import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import FollowingArticleCard from "../followingArticleCard/followingArticleCard";
import moduleCss from './followingArticleList.module.css';
import {bindActionCreators} from "redux";
import {selectFollowingAction} from "../../../../redux/actions/selectFollowingAction";
import {array, func, number, shape} from "prop-types";
import {netArticleByAuthor} from "../../../../network/articleNetwork";
import {Button, Empty, Skeleton} from "antd";

function FollowingArticleList(
    {
        selectedFollowing,
        initArticlePage,
        initArticleList,
        onSelectFollowing
    }
){

    const [page, setPage] = useState(initArticlePage);
    const [articleList, setArticleList] = useState(initArticleList);
    const [loading, setLoading] = useState(false);
    const [noMorePage, setNoMorePage] = useState(false);
    const [emptyPage, setEmptyPage] = useState(false);
    const pageSize = 20;

    useEffect(()=>{
        let didCancel = false;
        const requestArticleList = ()=>{
            setLoading(true);
            const param = {
                pageNo: page,
                pageSize: pageSize,
                authorId: selectedFollowing.id
            }
            netArticleByAuthor(param).then((res) => {
                if(res.status == 'S00' && !didCancel) {
                    if(res.result){
                        setArticleList(articles => [...articles, ...res.result]);
                        if(res.result.length < pageSize){
                            setNoMorePage(true);
                        }else{
                            setNoMorePage(false);
                        }
                    }else{
                        setNoMorePage(true);
                    }
                }
            }).finally(()=>{
                if( !didCancel) {
                    setLoading(false);
                }
            })
        }

        if(selectedFollowing){
            if(page == 0){
                setArticleList([]);
            }
            requestArticleList();
        }else{
            setEmptyPage(true);
        }

        return ()=>{
            didCancel = true;
        }

    }, [page, selectedFollowing]);

    useEffect(() => {
        return () => {
            onSelectFollowing();
        };
    }, [onSelectFollowing]);

    return (
        <div className={moduleCss.container}>
            {
                loading && articleList.length == 0 ? <Skeleton  active={loading}/> :
                articleList.map((article) => (
                    <FollowingArticleCard key={article.id} article={article}></FollowingArticleCard>
                ))
            }
            {
                articleList.length > 0 ? <Button
                    className={moduleCss.loadingMoreButton}
                    type={"link"}
                    disabled={noMorePage}
                    loading={loading}
                    onClick={()=>{
                        setPage(pageNo => pageNo + 1);
                    }}>{noMorePage ? articleList.length == 0 ? '该作者还没有文章' : '没有更多内容了～' : '加载更多'}</Button> :
                    null
            }
            {
                emptyPage ? <Empty description={false}/> : null
            }
        </div>
    )
}

FollowingArticleList.defaultProps = {}

FollowingArticleList.propTypes = {
    selectedFollowing: shape({}),
    initArticlePage: number,
    initArticleList: array,
    onSelectFollowing: func
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onSelectFollowing: selectFollowingAction,
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        selectedFollowing: state.selectFollowingReducer.following,
        initArticlePage: state.selectFollowingReducer.initArticlePage,
        initArticleList: state.selectFollowingReducer.initArticleList
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FollowingArticleList);
