import React from 'react';
import {connect} from "react-redux";
import {bool, func, string} from 'prop-types';
import {MenuContainer, MenuIcon, MenuText, MenuTextSelected} from "../css/styleMenuItem.style";
import 'src/App.css';

function MobileMenuItem ({
                       title,
                       onClick,
                       isSelected
                   }){
    return (
        <MenuContainer onClick={onClick}>
            {
                isSelected ? <MenuTextSelected>{title}</MenuTextSelected> : <MenuText>{title}</MenuText>
            }
        </MenuContainer>
    )
}

MobileMenuItem.propTypes = {
    title: string.isRequired,
    onClick: func.isRequired,
    isSelected: bool.isRequired
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MobileMenuItem);
