import React from "react";
import {connect} from "react-redux";
import {string} from "prop-types";
import moduleCss from "./settingMenu.module.css";
import {Button} from "antd";
import {UnorderedListOutlined} from "@ant-design/icons";

function SettingMenu(
    {
        currentMenu
    }
){
    return (
        <div className={moduleCss.container}>
            <Button type={"text"} className={`${moduleCss.menuItem} ${currentMenu == 'basic' ? moduleCss.selected : ''}`}>
                <UnorderedListOutlined />基本信息
            </Button>
        </div>
    )
}

SettingMenu.propTypes = {
    currentMenu: string
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingMenu);
