import moduleCss from "./loginButtonGroup.module.css";
import {Button, Modal} from "antd";
import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import SignIn from "../signIn/signIn";
import SignUp from "../signUp/signUp";
import {func} from "prop-types";
import SignInEventEmitter from "../../../../utils/signInEventEmitter";

function LoginButtonGroup(){
    const [isSignInModalVisible, setIsSignInModalVisible] = useState(false);
    const [isSignUpModalVisible, setIsSignUpModalVisible] = useState(false);

    const showModal = (type) => {
        if(type === "SIGN_IN"){
            setIsSignInModalVisible(true);
        }else{
            setIsSignUpModalVisible(true);
        }

    };

    const handleCancel = (type)=>{
        if(type === "SIGN_IN"){
            setIsSignInModalVisible(false);
        }else{
            setIsSignUpModalVisible(false);
        }
    }

    useEffect(()=>{
        SignInEventEmitter.addListener("SIGN_IN", ()=>{
            setIsSignInModalVisible(true);
        })
    }, [1])

    useEffect(()=>{
        SignInEventEmitter.removeListener("SIGN_IN", ()=>{

        });
    },[]);

    return (
        <div className={moduleCss.loginBtnGroup}>
            <Button type="link"
                    shape="round"
                    style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        height: '40px',
                        marginLeft: '10px',
                        color: '#999A9A'
                    }}
                    onClick={()=>{showModal("SIGN_IN")}}
            >登录</Button>
            <Button danger
                    shape="round"
                    style={{fontSize: '16px', fontWeight: 'bold', height: '40px', marginLeft: '10px'}}
                    onClick={()=>{showModal("SIGN_UP")}}
            >注册</Button>

            <Modal visible={isSignInModalVisible}
                   closable={false}
                   destroyOnClose={true}
                   footer={null}
                   width={"580px"}
                   onCancel={()=>{handleCancel("SIGN_IN")}}>
                <SignIn></SignIn>
            </Modal>
            <Modal visible={isSignUpModalVisible}
                   closable={false}
                   destroyOnClose={true}
                   footer={null}
                   width={"580px"}
                   onCancel={()=>{handleCancel("SIGN_UP")}}>
                <SignUp></SignUp>
            </Modal>
        </div>
    )
}

LoginButtonGroup.propTypes = {
    signInAction: func
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({

    }, dispatch);
}

const mapStateToProps = () => {
    return {

    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoginButtonGroup);
