import React from "react";
import { Route } from "react-router-dom";
import NavBar from "../components/navBar/navBar";
import { array } from "prop-types";

function BasicLayout({ routes = [] }) {
  return (
    <>
      <NavBar />
      <div className={"App"}>
        {routes.map((route, key) => {
          if (route.exact) {
            return (
              <Route
                key={key}
                exact
                path={route.path}
                render={(props) => (
                  <route.component {...props} routes={route.routes} />
                )}
              ></Route>
            );
          } else {
            return (
              <Route
                key={key}
                path={route.path}
                render={(props) => (
                  <route.component {...props} routes={route.routes} />
                )}
              ></Route>
            );
          }
        })}
      </div>
    </>
  );
}

BasicLayout.defaultProps = {};

BasicLayout.propTypes = {
  routes: array,
};

export default BasicLayout;
