import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Button, Skeleton, Tabs} from "antd";
import AuthorArticleCard from "./components/authorArticleCard/authorArticleCard";
import {BellFilled, FileTextFilled, MessageFilled} from "@ant-design/icons";
import AuthorTimelineCard from "./components/authorTimelineCard/authorTimelineCard";
import AuthorCommentCard from "./components/authorCommentCard/authorCommentCard";
import {shape, string} from "prop-types";
import {netArticleByAuthor} from "../../../../network/articleNetwork";
import moduleCss from "./authorArticle.module.css";
import {netToAuthorCommentList} from "../../../../network/authorNetwork";

function AuthorArticle({author}) {

    const [loading, setLoading] = useState(false);
    const [articlePage, setArticlePage] = useState(0);
    const [articleList, setArticleList] = useState([]);
    const [articleNoMorePage, setArticleNoMorePage] = useState(false);

    const [commentPage, setCommentPage] = useState(0);
    const [commentList, setCommentList] = useState([]);
    const [commentNoMorePage, setCommentNoMorePage] = useState(false);

    useEffect(() => {
        let didCancel = false;
        const param = {
            pageNo: articlePage,
            pageSize: 20,
            authorId: author.id
        }
        setLoading(true)
        netArticleByAuthor(param).then(res => {
            if (res.result && !didCancel) {
                setArticleList(articles => [...articles, ...res.result]);
                if (res.result.length < 20) {
                    setArticleNoMorePage(true);
                }
            }
        }).finally(() => {
            if (!didCancel) {
                setLoading(false)
            }
        })

        return () => {
            didCancel = true;
        }
    }, [articlePage, author.id])

    useEffect(() => {
        let didCancel = false;
        const param = {
            pageNo: commentPage,
            pageSize: 20,
            authorId: author.id
        }
        setLoading(true)
        netToAuthorCommentList(param).then(res => {
            if (res.result && !didCancel) {
                setCommentList(comment => [...comment, ...res.result]);
                if (res.result.length < 20) {
                    setCommentNoMorePage(true);
                }
            }
        }).finally(() => {
            if (!didCancel) {
                setLoading(false)
            }
        })

        return () => {
            didCancel = true;
        }
    }, [commentPage, author.id])

    return (
        <div>
            <Tabs>
                <Tabs.TabPane
                    tab={
                        <span>
                            <FileTextFilled/>
                          文章{author.articleCount}
                        </span>
                    }
                    key="article"
                >
                    <div className={moduleCss.container}>
                        {
                            articleList.length == 0 && loading ?
                                <Skeleton active></Skeleton>
                                :
                                articleList.map(article => (
                                    <AuthorArticleCard key={article.id} article={article}></AuthorArticleCard>))

                        }
                        {
                            articleList.length > 0 ?
                                <Button
                                    className={moduleCss.loadingMoreButton}
                                    type={"link"}
                                    loading={loading}
                                    disabled={articleNoMorePage}
                                    onClick={() => {
                                        setArticlePage(pageNo => pageNo + 1)
                                    }}>{articleNoMorePage ? '没有更多内容了～' : '加载更多'}</Button> : <></>
                        }
                    </div>
                </Tabs.TabPane>
                {/*<Tabs.TabPane*/}
                {/*    tab={*/}
                {/*        <span>*/}
                {/*            <BellFilled />*/}
                {/*          动态10*/}
                {/*        </span>*/}
                {/*    }*/}
                {/*    key="timeline"*/}
                {/*>*/}
                {/*    <AuthorTimelineCard></AuthorTimelineCard>*/}
                {/*    <AuthorTimelineCard></AuthorTimelineCard>*/}
                {/*    <AuthorTimelineCard></AuthorTimelineCard>*/}
                {/*    <AuthorTimelineCard></AuthorTimelineCard>*/}
                {/*    <AuthorTimelineCard></AuthorTimelineCard>*/}
                {/*    <AuthorTimelineCard></AuthorTimelineCard>*/}
                {/*</Tabs.TabPane>*/}
                <Tabs.TabPane
                    tab={
                        <span>
                            <MessageFilled/>
                          评论{author.commentCount}
                        </span>
                    }
                    key="comment"
                >
                    <div className={moduleCss.container}>
                        {
                            commentList.length == 0 && loading ?
                                <Skeleton active></Skeleton>
                                :
                                commentList.map(comment => (
                                    <AuthorCommentCard key={comment.id} comment={comment}></AuthorCommentCard>))

                        }
                        {
                            commentList.length > 0 ?
                                <Button
                                    className={moduleCss.loadingMoreButton}
                                    type={"link"}
                                    loading={loading}
                                    disabled={commentNoMorePage}
                                    onClick={() => {
                                        setCommentPage(pageNo => pageNo + 1)
                                    }}>{commentNoMorePage ? '没有更多内容了～' : '加载更多'}</Button> : <></>
                        }
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}

AuthorArticle.defaultProps = {}

AuthorArticle.propTypes = {
    author: shape({}),
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AuthorArticle);
