import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Button, Skeleton, Tabs} from "antd";
import {HeartFilled, StarFilled} from "@ant-design/icons";
import LikedArticleCard from "./components/likedArticleCard";
import {shape, string} from "prop-types";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import {
    netAuthorLikedArticle,
    netToAuthorCollectedArticle
} from "../../../../network/authorNetwork";
import moduleCss from "./authorLikedArticle.module.css";

function AuthorLikedArticle(
    {
        currentTab,
        author
    }
) {
    const [likePage, setLikePage] = useState(0);
    const [collectPage, setCollectPage] = useState(0);
    const [likeList, setLikeList] = useState([]);
    const [collectList, setCollectList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [likeNoMorePage, setLikeNoMorePage] = useState(false);
    const [collectNoMorePage, setCollectNoMorePage] = useState(false);

    useEffect(() => {
        if (!author.id) {
            return;
        }
        let didCancel = false;
        setLoading(true);
        const param = {
            authorId: author.id,
            pageNo: likePage,
            pageSize: 20
        }
        netAuthorLikedArticle(param).then(res => {
            if (res.result && !didCancel) {
                setLikeList(like => [...like, ...res.result]);
                if (res.result.length < 20) {
                    setLikeNoMorePage(true);
                }
            }
        }).finally(() => {
            if (!didCancel) {
                setLoading(false)
            }
        })
        return () => {
            didCancel = true
        }
    }, [author.id, likePage])

    useEffect(() => {
        if (!author.id) {
            return;
        }
        let didCancel = false;
        setLoading(true);
        const param = {
            authorId: author.id,
            pageNo: collectPage,
            pageSize: 20
        }
        netToAuthorCollectedArticle(param).then(res => {
            if (res.result && !didCancel) {
                setCollectList(collect => [...collect, ...res.result]);
                if (res.result.length < 20) {
                    setCollectNoMorePage(true);
                }
            }
        }).finally(() => {
            if (!didCancel) {
                setLoading(false)
            }
        })
        return () => {
            didCancel = true
        }
    }, [author.id, collectPage])

    const history = useHistory();

    const onTabClick = (key) => {
        history.push(`/author/${author.id}/` + key);
    }

    return (
        <div>
            <Tabs activeKey={currentTab} onTabClick={onTabClick}>
                <Tabs.TabPane
                    tab={
                        <span>
                            <HeartFilled />
                            我喜欢的文章
                        </span>
                    }
                    key="liked_article"
                >
                    <div className={moduleCss.container}>
                        {
                            likeList.length == 0 && loading ?
                                <Skeleton active></Skeleton>
                                :
                                likeList.map(like => {
                                    return <LikedArticleCard key={like.id} article={like}/>
                                })

                        }
                        {
                            likeList.length > 0 ?
                                <Button
                                    className={moduleCss.loadingMoreButton}
                                    type={"link"}
                                    loading={loading}
                                    disabled={likeNoMorePage}
                                    onClick={() => {
                                        setLikePage(pageNo => pageNo + 1)
                                    }}>{likeNoMorePage ? '没有更多内容了～' : '加载更多'}</Button> : <></>
                        }
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={
                        <span>
                            <StarFilled />
                            我收藏的文章
                        </span>
                    }
                    key="collected_article"
                >
                    <div className={moduleCss.container}>
                        {
                            collectList.length == 0 && loading ?
                                <Skeleton active></Skeleton>
                                :
                                collectList.map(collect => {
                                    return <LikedArticleCard key={collect.id} article={collect}/>
                                })

                        }
                        {
                            collectList.length > 0 ?
                                <Button
                                    className={moduleCss.loadingMoreButton}
                                    type={"link"}
                                    loading={loading}
                                    disabled={collectNoMorePage}
                                    onClick={() => {
                                        setCollectList(pageNo => pageNo + 1)
                                    }}>{collectNoMorePage ? '没有更多内容了～' : '加载更多'}</Button> : <></>
                        }
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}

AuthorLikedArticle.propTypes={
    currentTab: string,
    author: shape({})
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps( state) {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AuthorLikedArticle);
