import React from "react";
import {connect} from "react-redux";
import moduleCss from './followingUser.module.css';
import {func, shape} from "prop-types";
import {bindActionCreators} from "redux";
import {selectFollowingAction} from "../../../../redux/actions/selectFollowingAction";
import AvatarImg from "../../../../components/avatar/avatarImg";

function FollowingUser(
    {
        following,
        selectedFollowing,
        onSelectFollowing
    }
){
    return (
        <div className={`${moduleCss.container} ${following?.id == selectedFollowing?.id ? moduleCss.selected : ''}`}
             onClick={()=>{onSelectFollowing(following)}}
        >
            <AvatarImg className={moduleCss.authorAvatar} src={following.avatar} />
            <span className={moduleCss.authorName}>{following.nickname}</span>
        </div>
    )
}

FollowingUser.propTypes = {
    following: shape({}),
    selectedFollowing: shape({}),
    onSelectFollowing: func
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onSelectFollowing: selectFollowingAction,
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        selectedFollowing: state.selectFollowingReducer.following
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FollowingUser);
