import React from 'react';
import { connect } from 'react-redux';
import 'src/App.css';
import moduleCss from './following.story.module.css';
import FollowingList from "./components/followingList/followingList";
import FollowingArticleList from "./components/followingArticleList/followingArticleList";
import {DownCircleOutlined} from "@ant-design/icons";

function FollowingStory() {
    return (
        <div className={moduleCss.container}>
            <div className={moduleCss.followingList}>
                <div className={moduleCss.followingListTitle}>全部关注<DownCircleOutlined style={{color: '#C8C8C9', marginLeft:'6px'}}/></div>
                <FollowingList></FollowingList>
            </div>
            <div className={moduleCss.articleList}>
                <FollowingArticleList></FollowingArticleList>
            </div>
        </div>
    );
}

FollowingStory.defaultProps = {
}

FollowingStory.propTypes = {
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FollowingStory);
