import styled from 'styled-components';

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  @media (max-width: 768px) {
    line-height: 40px;
  }
`

const MenuIcon = styled.img`
  width: 20px;
  height: 20px;
`

const MenuText = styled.span`
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #3C3C3C;
  margin-left: 5px;
  @media (max-width: 768px) {
    font-size: 15px;
  }
`

const MenuTextSelected = styled.span`
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #427DFF;
  @media (max-width: 768px) {
    font-size: 15px;
  }
`

export {
    MenuIcon,
    MenuText,
    MenuTextSelected,
    MenuContainer
};
