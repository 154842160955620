import React, {useEffect, useState} from "react";
import moduleCss from "./articleFooter.module.css";
import {
  CommentOutlined,
  HeartOutlined, HeartTwoTone, ShareAltOutlined,
  StarOutlined, StarTwoTone
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import {Button, Form, message, Popover} from "antd";
import {bool, shape} from "prop-types";
import {netCollect, netComment, netLike, netUncollect, netUnlike} from "../../../../network/articleNetwork";
import SignInEventEmitter from '../../../../utils/signInEventEmitter'
import {getWxShareJsSig} from "../../../../network/shareNetwork";
import * as wx from "weixin-js-sdk"
import deviceUtil from "../../../../utils/deviceUtil";
import Modal from "antd/es/modal/Modal";
import QRCode from 'qrcode.react';
import pathParamUtil from "../../../../utils/pathParamUtil";

const ArticleFooter = ({
  article
} = {}) => {
  const [form] = Form.useForm();
  const [focused, setFocused] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  const [liked, setLiked] = useState(article.liked);
  const [likeCount, setLikeCount] = useState(article.likeCount);
  const [collected, setCollected] = useState(article.collected);
  const [collectCount, setCollectCount] = useState(article.collectCount);
  const [isMobile, setIsMobile] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [qrUrl, setQrUrl] = useState(null);
  useEffect(() => {
    setIsMobile(deviceUtil.isMobile);
  }, []);

  const param = pathParamUtil.getPathParam();

  const onSubmit = () => {
    const values = form.getFieldsValue();
    if (!values.content) {
      message.info("请输入评论内容")
      return;
    }
    const param = {
      articleId: article.id,
      content: values.content
    }
    setCommentLoading(true);
    netComment(param).then(res => {
      if (res.status == 'S00') {
        setFocused(false);
        form.resetFields();
      }
    }).finally(() => {
      setCommentLoading(false);
    })
  }

  const onShare = ()=>{
    setQrUrl(window.location.href)
    setIsShareModalVisible(true);
  }

  const initShareWithWeiXin = () => {
    const params = {
      url: window.location.href
    }
    getWxShareJsSig(params).then(res => {
      configWx(res.result);
    });
  }

  if(deviceUtil.isWxBrowser()){
    initShareWithWeiXin(param);
  }

  const configWx = (param)=>{
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: param.appId, // 必填，公众号的唯一标识
      timestamp: param.timestamp, // 必填，生成签名的时间戳
      nonceStr: param.nonceStr, // 必填，生成签名的随机串
      signature: param.signature,// 必填，签名
      jsApiList: [
        'onMenuShareAppMessage',
        'onMenuShareTimeline'
      ] // 必填，需要使用的JS接口列表
    })

    wx.ready((res)=>{
      console.log(article.pictureList);
      wx.onMenuShareAppMessage({
        title: article.articleTitle, // 分享标题
        desc: article.contentPreview, // 分享描述
        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: article.pictureList && article.pictureList.length > 0 ? article.pictureList[0].url : "https://shangan-static.oss-cn-beijing.aliyuncs.com/community_logo.png", // 分享图标
        success: function () {
          // 设置成功
        }
      });

      wx.onMenuShareTimeline({
        title: article.articleTitle, // 分享标题
        desc: article.contentPreview, // 分享描述
        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: article.pictureList && article.pictureList.length > 0 ? article.pictureList[0].url : "https://shangan-static.oss-cn-beijing.aliyuncs.com/community_logo.png", // 分享图标
        success: function () {
          // 设置成功
        }
      });
    })
  }

  return (
    <div className={moduleCss.footer}>
      <Modal title="分享到微信" visible={isShareModalVisible} footer={null} onCancel={()=>{setIsShareModalVisible(false)}}>
        <div className={moduleCss.shareContent}>
          <p>打开微信“扫一扫”，打开网页后点击屏幕右上角分享按钮</p>
          <QRCode value={qrUrl}
                  size={200}
          ></QRCode>
        </div>
      </Modal>
      <div className={moduleCss.footerContent}>
        <Form
            form={form}
            name="basic"
            labelCol={{span: 0}}
            wrapperCol={{span: 24}}
            initialValues={{remember: true}}
            onFinish={null}
            onFinishFailed={null}
            autoComplete="off"
            className={moduleCss.textareaForm}
        >
          <Form.Item
              label=""
              name="content"
              rules={[{required: false, message: "请先输入内容"}]}
              style={{marginBottom: 0}}
          >
            <TextArea
                // rows={focused ? 3 : 1}
                autoSize={{ minRows: 1, maxRows: 3 }}
                className={moduleCss.commentTextarea}
                placeholder="写下你的评论.."
                onFocus={()=>{setFocused(true)}}
            />
          </Form.Item>
        </Form>
        {
          focused ? <div className={moduleCss.commentButtonGroup}>
            <Button
                type="primary"
                shape="round"
                size="small"
                className="follow-btn"
                loading={commentLoading}
                onClick={() => {
                  if(SignInEventEmitter.judgeLogin()){
                    onSubmit();
                  }
                }}
                style={{marginRight: "10px"}}
            >
              发布
            </Button>
            <Button
                type="default"
                shape="round"
                size="small"
                className="follow-btn"
                onClick={() => {
                  setFocused(false);
                  form.resetFields();
                }}
            >
              取消
            </Button>
          </div> : null
        }
        {
          !focused ? <div className={moduleCss.footerBlockGroup}>
            <div className={moduleCss.footerBlock}>
              <CommentOutlined />
              <div>{`评论${article.commentCount ?? 0}`}</div>
            </div>
            <div className={moduleCss.footerBlock} onClick={()=>{
              if(!SignInEventEmitter.judgeLogin()){
                return;
              }
              if(liked){
                netUnlike({entityId: article.id, type:'article'}).then(res =>{
                  if(res.status == 'S00'){
                    setLiked(false);
                    setLikeCount(likeCount - 1);
                  }
                })
              }else{
                netLike({entityId: article.id, type:'article'}).then(res => {
                  if(res.status == 'S00'){
                    setLiked(true);
                    setLikeCount(likeCount + 1);
                  }
                })
              }
            }}>
              {liked ? <HeartTwoTone twoToneColor={"#eb2f96"}/> : <HeartOutlined />} <div style={{color: liked ? "#eb2f96" : "black"}}>{`赞${likeCount ?? 0}`}</div>
            </div>
            <div className={moduleCss.footerBlock} onClick={()=>{
              if(!SignInEventEmitter.judgeLogin()){
                return;
              }
              if(collected){
                netUncollect({articleId: article.id}).then(res => {
                  if(res.status == 'S00'){
                    setCollected(false);
                    setCollectCount(collectCount - 1);
                  }
                })
              }else{
                netCollect({articleId: article.id}).then(res => {
                  if(res.status == 'S00'){
                    setCollected(true);
                    setCollectCount(collectCount + 1);
                  }
                })
              }

            }}>
              {collected ? <StarTwoTone twoToneColor={"#eb2f96"}/> : <StarOutlined />} <div style={{color: collected ? "#eb2f96" : "black"}}>{`收藏${collectCount ?? 0}`}</div>
            </div>
            {
              isMobile ? <></> :
                  <div className={moduleCss.footerBlock} onClick={onShare}>
                    <ShareAltOutlined />分享
                  </div>
            }
          </div> : null
        }
      </div>
    </div>
  );
};

ArticleFooter.propTypes = {
  article: shape({})
};

export default ArticleFooter;
