import React from 'react';
import {connect} from "react-redux";
import {bool, func, string} from 'prop-types';
import {MenuContainer, MenuIcon, MenuText, MenuTextSelected} from "../css/styleMenuItem.style";
import 'src/App.css';

function MenuItem ({
    icon,
    selectedIcon,
    title,
    onClick,
    isSelected
}){
    return (
        <MenuContainer onClick={onClick}>
            <MenuIcon src={isSelected ? selectedIcon : icon}></MenuIcon>
            {
                isSelected ? <MenuTextSelected>{title}</MenuTextSelected> : <MenuText>{title}</MenuText>
            }
        </MenuContainer>
    )
}

MenuItem.propTypes = {
    icon: string.isRequired,
    selectedIcon: string.isRequired,
    title: string.isRequired,
    onClick: func.isRequired,
    isSelected: bool.isRequired
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MenuItem);
