import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import 'src/App.css';
import ArticleCard from "../../components/articleCard/articleCard";
import {netArticleDiscovery} from "../../network/articleNetwork";
import {Button} from "antd";
import moduleCss from "./search.story.module.css";
import {object, shape} from "prop-types";

function SearchStory() {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNo, setPageNo] = useState(0);
    const [noMorePage, setNoMorePage] = useState(false);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [searchKey] = useState(params.get('q'));
    useEffect(()=>{
        let didCancel = false;
        function requestArticleList (){
            setLoading(true);
            const param = {
                pageNo: pageNo,
                pageSize: 20,
                searchKey: searchKey
            }
            netArticleDiscovery(param).then((res) => {
                if(res.result && !didCancel){
                    setArticles(articles => [...articles, ...res.result]);
                    if(res.result.length < 20){
                        setNoMorePage(true);
                    }
                }

            }).finally(()=>{
                if(!didCancel){
                    setLoading(false);
                }
            })
        }
        requestArticleList();
        return ()=>{
            didCancel = true;
        }
    }, [pageNo]);

    return (
        <div className={moduleCss.container}>
            <ArticleCard
                article={articles}
            />
            <Button
                className={moduleCss.loadingMoreButton}
                type={"link"}
                loading={loading}
                disabled={noMorePage}
                onClick={()=>{
                setPageNo(pageNo => pageNo + 1)
            }}>{noMorePage ? '没有更多内容了～' : '加载更多'}</Button>
        </div>
    );
}

SearchStory.defaultProps = {
}

SearchStory.propTypes = {
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SearchStory);
