import React, {
    useState,
    useMemo,
    useRef,
    useEffect,
    useSyncExternalStore,
} from "react";
import styles from "./index.module.css";
import {
    Row,
    Col,
    Input,
    Modal,
    Upload,
    message,
    Select,
    DatePicker,
    Tag,
} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import ReactQuill, {Quill} from "react-quill";
import {ImageBlot, LinkBlot} from "./CustomBlot";
import "react-quill/dist/quill.snow.css";
import {func, object, string} from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import moment from "moment";
import {
    netAuditResult,
    netPublishArticle,
    netSaveArticleDraft,
} from "../../../../network/writingNetwork";
import OSS from "ali-oss";

import {HOST} from "../../../../utils/config";
import {netFileToken} from "../../../../network/fileNetwork";
import {netGetDictionaryList} from "../../../../network/commonNetwork";
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import ImagePaste from "./ImagePaste";
import TagSelector from "../../../../components/tagSelector/tagSelector";
import {changeArticleTitle} from "../../../../redux/actions/writingAction"; // 调整大小组件。

const {Dragger} = Upload;
const {Option} = Select;

Quill.register("modules/imagePaste", ImagePaste);
Quill.register(ImageBlot);
Quill.register(LinkBlot);
const fontSizes = [
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "24px",
    "32px",
    "48px",
    "64px",
    "72px",
];
var FontAttributor = Quill.import("attributors/style/size");
FontAttributor.whitelist = fontSizes;
Quill.register(FontAttributor, true);
Quill.register("modules/resizeImage ", resizeImage);

const fonts = [
    "SimSun",
    "SimHei",
    "Microsoft-YaHei",
    "KaiTi",
    "FangSong",
    "Arial",
    "Times-New-Roman",
    "sans-serif",
];
var Font = Quill.import("formats/font");
Font.whitelist = fonts; //将字体加入到白名单
Quill.register(Font, true);

const WriteArticle = ({article, category, user, changeArticleTitle}) => {
    const [saving, setSaving] = useState(false);
    const [publishing, setPublishing] = useState(false);
    const [imageModalVisible, setImageModalVisible] = useState(false);
    const [linkModalVisible, setLinkModalVisible] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [imageWidth, setImageWidth] = useState(300);
    const [imageAlt, setImageAlt] = useState("");
    const [linkText, setLinkText] = useState("");
    const [linkUrl, setLinkUrl] = useState("");
    const [sts, setSts] = useState();
    const [store, setStore] = useState();
    const [lastPosition, setLastPosition] = useState(0);
    //   let quillRef = null;
    const quillRef = useRef(null);
    const [companyList, setCompanyList] = useState([]);
    const [reasonList, setReasonList] = useState([]);
    const [auditResult, setAuditResult] = useState();

    useEffect(() => {
        let didCancel = false;

        function requestCompanyList() {
            const param = {
                id: "company",
            };
            netGetDictionaryList(param).then((res) => {
                if (res.status == "S00" && res.result && !didCancel) {
                    setCompanyList(res.result);
                }
            });
        }

        function requestReasonList() {
            const param = {
                id: "reason",
            };
            netGetDictionaryList(param).then((res) => {
                if (res.status == "S00" && res.result && !didCancel) {
                    setReasonList(res.result);
                }
            });
        }

        requestCompanyList();
        requestReasonList();

        return () => {
            didCancel = true;
        };
    }, [setCompanyList, setReasonList]);


    useEffect(() => {
        getAuditResult();
        getFileToken();
    }, [article]);

    const formats = [
        "font",
        "size",
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "code-block",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "align",
        "color",
        "background",
        "link",
    ];

    const saveHandler = () => {
        if(!article.articleTitle || article.articleTitle.length == 0){
            message.info("请输入文章标题");
            return;
        }
        if (category.id == "01" && article.interviewCondition) {
            if(article.interviewCondition){
                if (!article.interviewCondition.company) {
                    message.info("请选择面试公司");
                    return;
                }
                if (!article.interviewCondition.reason) {
                    message.info("请选择面试原因");
                    return;
                }
                if (!article.interviewCondition.year) {
                    message.info("请选择面试年份");
                    return;
                }
            }else {
                message.info("请选择面试公司、面试原因和面试年份");
                return;
            }

        }
        if (!article.tags || article.tags.length == 0) {
            message.info("请选择至少一个标签");
            return;
        }
        setSaving(true);
        article.categoryId = category.id;
        netSaveArticleDraft(article)
            .then((res) => {
                if (res.status == "S00") {
                    article.id = res.result.id;
                    article.modifiedTime = res.result.modifiedTime;
                    message.success("保存成功");
                }
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const publishHandler = () => {
        setPublishing(true);
        article.categoryId = category.id;
        netPublishArticle(article)
            .then((res) => {
                if (res.status == "S00") {
                    article.id = res.result.id;
                    article.modifiedTime = res.result.modifiedTime;
                    article.publishedTime = res.result.publishedTime;
                    message.success("发布成功");
                }
            })
            .finally(() => {
                setPublishing(false);
            });
    };

    const getAuditResult = () => {
        if (!article || !article.id) {
            return;
        }
        netAuditResult({articleId: article.id}).then((res) => {
            if (res.status == "S00") {
                setAuditResult(res.result);
            }
        });
    };

    const imageHandler = () => {
        // 弹窗出来后会失焦，导致获取不到position，所以先获取一下
        let quill = quillRef.current.getEditor(); //获取到编辑器本身
        const cursorPosition = quill.getSelection().index;
        setLastPosition(cursorPosition);
        getFileToken();
        setImageModalVisible(true);
    };

    const linkHandler = () => {
        let quill = quillRef.current.getEditor(); //获取到编辑器本身
        const cursorPosition = quill.getSelection().index;
        setLastPosition(cursorPosition);
        setLinkModalVisible(true);
    };

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{header: [1, 2, 3, 4, 5, false]}],
                    [{font: fonts}],
                    [
                        {
                            size: fontSizes,
                        },
                    ],
                    [
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        {color: []},
                        {background: []},
                    ],
                    [{align: []}],
                    [{list: "ordered"}, {list: "bullet"}],
                    [{indent: "-1"}, {indent: "+1"}],
                    ["code-block", "blockquote"],
                    ["link", "image"],
                    ["clean"],
                    ["save", "publish"],
                ],
                handlers: {
                    image: imageHandler,
                    link: linkHandler,
                    save: saveHandler,
                    publish: publishHandler,
                },
            },
            imageResize: {
                //调整大小组件。
                displayStyles: {
                    backgroundColor: "black",
                    border: "none",
                    color: "white",
                },
                modules: ["Resize", "DisplaySize", "Toolbar"],
            },
            imagePaste: {
                addImageBlob: function (file, callback) {
                    const fileName = file.name;
                    const suffix = fileName.slice(fileName.lastIndexOf(".") + 1);
                    store
                        .multipartUpload(
                            `${user.id}/article/${new Date().getTime()}.${suffix}`,
                            file
                        )
                        .then((result) => {
                            if (result && result.res && result.res.status == 200) {
                                callback(HOST.image + "/" + result.name);
                            }
                        });
                },
            },
        }),
        [category, article, store]
    );

    const getFileToken = () => {
        netFileToken().then((res) => {
            if (res.status == "S00") {
                setSts(res.result);
                setStore(
                    new OSS({
                        accessKeyId: res.result.AccessKeyId,
                        accessKeySecret: res.result.AccessKeySecret,
                        bucket: res.result.bucketName,
                        endpoint: HOST.ossEndpoint,
                        stsToken: res.result.SecurityToken,
                        refreshSTSToken: async () => {
                            netFileToken().then((res) => {
                                if (res.status == "S00") {
                                    return {
                                        accessKeyId: res.result.AccessKeyId,
                                        accessKeySecret: res.result.AccessKeySecret,
                                        stsToken: res.result.SecurityToken,
                                    };
                                }
                            });
                        },
                        refreshSTSTokenInterval: 300000,
                    })
                );
            }
        });
    };

    const onAddImage = async (e) => {
        if (e && e.event && e.event.percent == 100) {
            const store = new OSS({
                accessKeyId: sts.AccessKeyId,
                accessKeySecret: sts.AccessKeySecret,
                bucket: sts.bucketName,
                endpoint: HOST.ossEndpoint,
                stsToken: sts.SecurityToken,
                refreshSTSToken: async () => {
                    netFileToken().then((res) => {
                        if (res.status == "S00") {
                            return {
                                accessKeyId: res.result.AccessKeyId,
                                accessKeySecret: res.result.AccessKeySecret,
                                stsToken: res.result.SecurityToken,
                            };
                        }
                    });
                },
                refreshSTSTokenInterval: 300000,
            });

            let suffix = e.file.name.split(".").reverse()[0];

            setUploading(true);
            const result = await store.multipartUpload(
                `${user.id}/article/${new Date().getTime()}.${suffix}`,
                e.file.originFileObj
            );

            if (result && result.res && result.res.status == 200) {
                setImageUrl(HOST.image + "/" + result.name);
            }
            setUploading(false);
        }
    };

    return article ? (
        <>
            <Modal
                title={"插入图片"}
                visible={imageModalVisible}
                okText={"确定"}
                cancelText={"取消"}
                onOk={() => {
                    let quill = quillRef.current.getEditor(); //获取到编辑器本身
                    quillRef.current.focus();
                    quill.insertEmbed(lastPosition, "image", {
                        url: imageUrl,
                        alt: imageAlt,
                        width: `${imageWidth}px`,
                    });
                    quill.setSelection(lastPosition + 1); //光标位置加1
                    setImageModalVisible(false);
                    setImageUrl(null);
                    setImageWidth(300);
                    setImageAlt("");
                }}
                destroyOnClose
                onCancel={() => {
                    setImageModalVisible(false);
                    setImageUrl(null);
                    setImageWidth(300);
                    setImageAlt("");
                }}
            >
                <Row style={{marginTop: "16px"}}>
                    <Col span={6}>
                        <label style={{lineHeight: "32px"}}>选择图片:</label>
                    </Col>
                    <Col span={16} style={{maxHeight: "500px", overflow: "auto"}}>
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                alt="img"
                                style={{width: "100%", maxHeight: "300px"}}
                            />
                        ) : (
                            <Dragger onChange={onAddImage} showUploadList={false}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined/>
                                </p>
                                <p className="ant-upload-text">点击上传或拖拽上传</p>
                            </Dragger>
                        )}
                    </Col>
                </Row>
                <Row style={{marginTop: "16px"}}>
                    <Col span={6}>
                        <label style={{lineHeight: "32px"}}>图片ALT:</label>
                    </Col>
                    <Col span={16}>
                        <Input
                            defaultValue=""
                            value={imageAlt}
                            onChange={(e) => setImageAlt(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: "16px"}}>
                    <Col span={6}>
                        <label style={{lineHeight: "32px"}}>图片宽度:</label>
                    </Col>
                    <Col span={16}>
                        <Input
                            addonAfter="px"
                            defaultValue="300"
                            onChange={(e) => setImageWidth(e.target.value)}
                        />
                    </Col>
                </Row>
            </Modal>
            <Modal
                title={"插入链接"}
                visible={linkModalVisible}
                okText={"确定"}
                cancelText={"取消"}
                onOk={() => {
                    let quill = quillRef.current.getEditor(); //获取到编辑器本身
                    quillRef.current.focus();
                    quill.insertEmbed(lastPosition, "link", {
                        href: linkUrl,
                        text: linkText,
                    });
                    quill.setSelection(lastPosition + linkText.length); //光标位置加1
                    setLinkModalVisible(false);
                    setLinkText("");
                    setLinkUrl("");
                }}
                destroyOnClose
                onCancel={() => {
                    setLinkText("");
                    setLinkUrl("");
                    setLinkModalVisible(false);
                }}
            >
                <Row style={{marginTop: "16px"}}>
                    <Col span={6}>
                        <label style={{lineHeight: "32px"}}>链接文本:</label>
                    </Col>
                    <Col span={16}>
                        <Input
                            defaultValue=""
                            onChange={(e) => {
                                setLinkText(e.target.value);
                                e.stopPropagation();
                            }}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: "16px"}}>
                    <Col span={6}>
                        <label style={{lineHeight: "32px"}}>链接地址:</label>
                    </Col>
                    <Col span={16}>
                        <Input
                            defaultValue=""
                            onChange={(e) => setLinkUrl(e.target.value)}
                        />
                    </Col>
                </Row>
            </Modal>
            <div className={styles.header}>
                <Input
                    placeholder="请输入标题"
                    defaultValue={article?.articleTitle}
                    key={article?.id}
                    className={styles.titleInput}
                    onChange={(e) => {
                        article.articleTitle = e.target.value;
                        changeArticleTitle(article?.id, article?.articleTitle)
                    }}
                />
                <div className={styles.publishGroup}>
                    <div className={styles.publishTime}>
                        {article.modifiedTime
                            ? "最新保存：" +
                            moment
                                .unix(article.modifiedTime / 1000)
                                .format("YYYY.MM.DD HH:mm:ss")
                            : "未保存"}
                    </div>
                    <div className={styles.publishTime}>
                        {article.publishedTime ? (
                            auditResult ? (
                                auditResult.result == "1" ? (
                                    <Tag color="green">审核通过</Tag>
                                ) : (
                                    <Tag color="error">驳回：{auditResult.auditComment}</Tag>
                                )
                            ) : (
                                <Tag color="default">审核中</Tag>
                            )
                        ) : (
                            <></>
                        )}
                        {article.publishedTime
                            ? "最新发布：" +
                            moment
                                .unix(article.publishedTime / 1000)
                                .format("YYYY.MM.DD HH:mm:ss")
                            : "未发布"}
                    </div>
                </div>
            </div>
            <div className={styles.interviewOptionsContent}>
                <Row>
                    {
                        category.id == '01' ? <>
                                <Col span={4}>
                                    公司：
                                    <Select
                                        style={{width: 120}}
                                        showSearch
                                        key={article.id}
                                        defaultValue={article.company}
                                        onChange={(e) => {
                                            if (!article.interviewCondition) {
                                                article.interviewCondition = {};
                                            }
                                            article.interviewCondition.company = e;
                                        }}
                                        placeholder={"必填"}
                                    >
                                        {companyList.map((dict) => (
                                            <Option key={dict.id} value={dict.code}>
                                                {dict.value}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={4}>
                                    原因：
                                    <Select
                                        style={{width: 120}}
                                        key={article.id}
                                        defaultValue={article?.reason}
                                        onChange={(e) => {
                                            if (!article.interviewCondition) {
                                                article.interviewCondition = {};
                                            }
                                            article.interviewCondition.reason = e;
                                        }}
                                        placeholder={"必填"}
                                    >
                                        {reasonList.map((dict) => (
                                            <Option key={dict.id} value={dict.code}>
                                                {dict.value}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={4}>
                                    年份：
                                    <DatePicker
                                        picker="year"
                                        key={article.id}
                                        defaultValue={article?.year ? moment(article?.year) : null}
                                        onChange={(e, str) => {
                                            if (!article.interviewCondition) {
                                                article.interviewCondition = {};
                                            }
                                            article.interviewCondition.year = str;
                                        }}
                                        placeholder={"必填"}
                                    />
                                </Col>
                            </> :
                            <></>
                    }
                    <Col span={10}>
                        <span style={category.id == '01' ? {marginLeft: '20px'} : {marginLeft: '0px'}}>标签：</span>
                        <TagSelector
                            articleId={article.id}
                            tags={article.tags ?? []}
                            onChange={(tags) => {
                                article.tags = tags;
                            }}
                        ></TagSelector>
                    </Col>
                </Row>
            </div>
            <ReactQuill
                ref={quillRef}
                theme="snow"
                className={styles.richText}
                modules={modules}
                formats={formats}
                defaultValue={article?.content}
                key={article?.id}
                onChange={(e) => {
                    article.content = e;
                }}
            />
        </>
    ) : (
        <></>
    );
};

WriteArticle.defaultProps = {};

WriteArticle.propTypes = {
    changeArticleTitle: func,
    article: object,
    category: object,
    user: object,
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            changeArticleTitle: changeArticleTitle
        },
        dispatch
    );
};

function mapStateToProps(state) {
    return {
        article: state.writingReducer.article,
        category: state.writingReducer.category,
        user: state.userReducer.user,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WriteArticle);
