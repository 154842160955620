import React, {useEffect, useState} from "react";
import moduleCss from "./authorBlock.module.css";
import {Button, Divider} from "antd";
import AvatarImg from "../../../../components/avatar/avatarImg";
import {bool, func, shape} from "prop-types";
import {netFollow, netUnfollow} from "../../../../network/authorNetwork";
import {bindActionCreators} from "redux";
import {followAction} from "../../../../redux/actions/authorAction";
import {connect} from "react-redux";
import {netArticleByAuthor} from "../../../../network/articleNetwork";
import SignInEventEmitter from "../../../../utils/signInEventEmitter";
import {Link} from "react-router-dom";

const AuthorBlock = ({
                         author,
                         followed,
                         followAction
                     } = {}) => {
    const [loading, setLoading] = useState(false);
    const [loadingAuthorArticle, setLoadingAuthorArticle] = useState(false);
    const [articleList, setArticleList] = useState([]);

    useEffect(()=>{
      setLoadingAuthorArticle(true);
      const param = {
        pageNo: 0,
        pageSize: 3,
        authorId: author.id
      }
      netArticleByAuthor(param).then((res) => {
        if(res.status == 'S00' && res.result) {
          setArticleList(res.result);
        }
      })
    }, [loadingAuthorArticle, followAction, followed, author?.id])

    return (
        author ?
            <div className={moduleCss.authorBlock}>
                <div className={moduleCss.articleAuthorBlock}>
                    <Link target = "_blank" to={`/author/${author.id}`}>
                        <AvatarImg src={author.avatar} size={48}/>
                    </Link>
                    <div className={moduleCss.articleAuthorInfo}>
                        <div className={moduleCss.articleAuthor}>
                            <Link target = "_blank" to={`/author/${author.id}`}>
                                <span className="article-author-name">{author.nickname}</span>
                            </Link>
                            <Button
                                type="primary"
                                ghost
                                shape="round"
                                size="small"
                                className="follow-btn"
                                loading={loading}
                                onClick={() => {
                                    if(!SignInEventEmitter.judgeLogin()){
                                        return;
                                    }
                                    setLoading(true);
                                    if (followed) {
                                        netUnfollow({authorId: author.id}).then((res) => {
                                            if (res.status == 'S00') {
                                                followAction(false);
                                            }

                                        }).finally(() => {
                                            setLoading(false);
                                        })
                                    } else {
                                        netFollow({authorId: author.id}).then((res) => {
                                            if (res.status == 'S00') {
                                                followAction(true);
                                            }
                                        }).finally(() => {
                                            setLoading(false);
                                        })
                                    }
                                }}
                            >
                                {followed ? "取消关注" : "关注"}
                            </Button>
                        </div>
                        <div className="article-author-extra">{`文章数 ${author.articleCount}`}</div>
                    </div>
                </div>
                <Divider style={{margin: "15px 0"}}/>

                {articleList.map((item) => (
                    <div key={item.id} className="rec-block">
                        <Link target="_blank" to={`/detail/${item.id}`}>
                            <div className={moduleCss.recName}>{item.articleTitle}</div>
                        </Link>
                        <div className={moduleCss.recDesc}>{`阅读 ${item.readCount ?? 0}`}</div>
                    </div>
                ))}
            </div> : <div></div>
    );
};

AuthorBlock.propTypes = {
    author: shape({}),
    followed: bool,
    followAction: func,
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        followAction: followAction
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        followed: state.authorReducer.followed
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorBlock);
