import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import {object, string} from "prop-types";

const NotiTypes = ({ types = {}, current = "COMMENTS" } = {}) => {
  return (
    <div className={styles.notiTypes}>
      {Object.keys(types).map((item) => (
        <Link to={`/message/${types[item].key}`} key={item}>
          <div
            className={`${styles.typeBlock} ${
              current === item ? styles.current : ""
            }`}
          >
            {types[item].icon}
            <div className={styles.typeText}>{types[item].text}</div>
          </div>
        </Link>
      ))}
    </div>
  );
};

NotiTypes.propTypes = { types: object, current: string };

export default NotiTypes;
