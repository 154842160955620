export default (
  state = { following: null, initArticlePage: 0, initArticleList: [] },
  action
) => {
  switch (action.type) {
    case "SELECT_FOLLOWING_ACTION":
      return {
        ...state,
        following: action.following,
        initArticlePage: 0,
        initArticleList: [],
      };
    default: {
      return state;
    }
  }
};
