import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.css";
import { Popover } from "antd";
import {
  PicLeftOutlined,
  RestOutlined,
  FontSizeOutlined,
  BuildOutlined,
} from "@ant-design/icons";
import { func, object, string } from "prop-types";
import { netGetArticleCategory } from "../../../../network/writingNetwork";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setCurrentCategory } from "../../../../redux/actions/writingAction";

const WritingMenu = ({ setCurrentCategory, category } = {}) => {
  const [articleCategory, setArticleCategory] = useState([]);

  useEffect(() => {
    let didCancel = false;

    function requestCategory() {
      netGetArticleCategory().then((res) => {
        if (res.status == "S00" && !didCancel) {
          setArticleCategory(res.result);
          setCurrentCategory(res.result[0]);
        }
      });
    }

    requestCategory();
    return () => {
      didCancel = true;
    };
  }, [setCurrentCategory]);

  // const settingsMenu = () => (
  //   <div className={styles.menu}>
  //     <div className={styles.editor}>
  //       <BuildOutlined />
  //       <div>默认编辑器</div>
  //     </div>
  //     <div className={styles.mode}>
  //       <FontSizeOutlined />
  //       <div>设置显示模式</div>
  //     </div>
  //     <div className={styles.trashBin}>
  //       <RestOutlined />
  //       <div>回收站</div>
  //     </div>
  //   </div>
  // );

  return (
    <>
      <Link to="/">
        <div className={styles.backBtn}>回首页</div>
      </Link>
      <div className={styles.writingTypes}>
        {articleCategory.map((item) => (
          <div
            key={item.id}
            className={`${styles.typeBlock} ${
              category === item ? styles.current : ""
            }`}
            onClick={() => (category === item ? "" : setCurrentCategory(item))}
          >
            <div className={styles.typeText}>{item.title}</div>
          </div>
        ))}
      </div>
      {/*<div className={styles.bottomActions}>*/}
      {/*  <Popover placement="topLeft" content={settingsMenu} trigger="click">*/}
      {/*    <div className={styles.settings}>*/}
      {/*      <PicLeftOutlined style={{ marginRight: "6px" }} />*/}
      {/*      设置*/}
      {/*    </div>*/}
      {/*  </Popover>*/}
      {/*</div>*/}
    </>
  );
};

WritingMenu.defaultProps = {};

WritingMenu.propTypes = {
  setCurrentCategory: func,
  category: object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setCurrentCategory: setCurrentCategory,
    },
    dispatch
  );
};

function mapStateToProps(state) {
  return {
    category: state.writingReducer.category,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WritingMenu);
