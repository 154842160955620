import { EventEmitter } from 'events'

class SignInEventEmitter extends EventEmitter {
    judgeLogin(){
        if(localStorage.getItem('token')){
            return true;
        }else{
            this.emit('SIGN_IN');
            return false;
        }
    }
}

export default new SignInEventEmitter();
