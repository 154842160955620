import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import "src/App.css";
import moduleCss from "./navBar.module.css";
import MenuItem from "./components/menuItem";
import discoveryIconUrl from "../shared/img/fx-h.png";
import discoverySelectedIconUrl from "../shared/img/fx-l.png";
import interviewIcon from "../shared/img/mj-h.png";
import interviewSelectedIcon from "../shared/img/mj-l.png";
import workExIcon from "../shared/img/gzjy-h.png";
import workSelectedExIcon from "../shared/img/gzjy-l.png";
import academicIconUrl from "../shared/img/academic-h.png";
import academicSelectedIconUrl from "../shared/img/academic-l.png";
import followIconUrl from "../shared/img/gz-h.png";
import followSelectedIconUrl from "../shared/img/gz-l.png";
import messageIconUrl from "../shared/img/xx-h.png";
import messageSelectedIconUrl from "../shared/img/xx-l.png";
import qaIconUrl from "../shared/img/qa-h.png";
import qaSelectedIconUrl from "../shared/img/qa-l.png";
import searchIconUrl from "../shared/img/ss.png";
import {bindActionCreators} from "redux";
import {func, shape} from "prop-types";
import {Link} from "react-router-dom";
import LoginButtonGroup from "./components/loginButtonGroup/loginButtonGroup";
import UserMenu from "./components/userMenu/userMenu";
import {Input} from "antd";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import SignInEventEmitter from "../../utils/signInEventEmitter";
import deviceUtil from "../../utils/deviceUtil";
import MobileMenuItem from "./components/mobileMenuItem";

function NavBar({user}) {
    const [currentMenu, setCurrentMenu] = useState("discovery");
    const [isSignedIn, setIsSignedIn] = useState(false);
    const history = useHistory();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [searchKey, setSearchKey] = useState(params.get('q'));
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(deviceUtil.isMobile);
    }, []);
    useEffect(() => {
        if (user) {
            setIsSignedIn(true);
        } else {
            setIsSignedIn(false);
        }
    }, [user, user?.id]);

    useEffect(() => {
        let location = window.location;
        if (location.pathname == "/") {
            setCurrentMenu("discovery");
        } else {
            const routeList = location.pathname.split("/");
            let currentRoute = "";
            if (routeList.length == 2) {
                currentRoute = routeList[routeList.length - 1];
                setCurrentMenu(currentRoute);
            } else {
                setCurrentMenu("xx");
            }
        }
    }, [setCurrentMenu]);

    const onSearch = () => {
        if(searchKey.length > 0 && history.location.pathname.indexOf('/search') == -1){
            const w = window.open('about:blank');
            w.location.href=`/search?q=${searchKey}`;
        }else{
            window.location.href=`/search?q=${searchKey}`;
        }
    }

    return isMobile ? (
        <div className={moduleCss.content}>
            <div className={moduleCss.headerTop}>
                <Link to="/">
                    <div className={moduleCss.logo}>
                        {/*<img src={logoUrl} style={{width: "100px"}}/>*/}
                        <div className={moduleCss.logoTitle}>无垠</div>
                    </div>
                </Link>
                {isSignedIn ? (
                    <UserMenu></UserMenu>
                ) : (
                    <LoginButtonGroup></LoginButtonGroup>
                )}
            </div>
            <div className={moduleCss.mobileCategory}>
                <Link to="/">
                    <MobileMenuItem
                        title="发现"
                        isSelected={currentMenu === "discovery"}
                        onClick={() => setCurrentMenu("discovery")}
                    />
                </Link>
                <Link to="/academic">
                    <MobileMenuItem
                        title="知识学习"
                        isSelected={currentMenu === "academic"}
                        onClick={() => setCurrentMenu("academic")}
                    />
                </Link>
                <Link to="/qa">
                    <MobileMenuItem
                        title="问答"
                        isSelected={currentMenu === "qa"}
                        onClick={() => setCurrentMenu("qa")}
                    />
                </Link>
                <Link to="/interview_experience">
                    <MobileMenuItem
                        title="面经"
                        isSelected={currentMenu === "interview_experience"}
                        onClick={() => setCurrentMenu("interview_experience")}
                    />
                </Link>
                <Link to="/work_experience">
                    <MobileMenuItem
                        title="工作经验"
                        isSelected={currentMenu === "work_experience"}
                        onClick={() => setCurrentMenu("work_experience")}
                    />
                </Link>
            </div>
        </div>
    ) : (
        <div className={moduleCss.content}>
            <div className={moduleCss.logo}>
                <Link to="/">
                    {/*<img src={logoUrl} style={{width: "100px"}}/>*/}
                    <div className={moduleCss.logoTitle}>无垠</div>
                </Link>
            </div>
            <div className={moduleCss.menu}>
                <Link to="/">
                    <MenuItem
                        title="发现"
                        icon={discoveryIconUrl}
                        selectedIcon={discoverySelectedIconUrl}
                        isSelected={currentMenu === "discovery"}
                        onClick={() => setCurrentMenu("discovery")}
                    />
                </Link>
                <Link to={"/academic"}>
                    <MenuItem
                        title="知识学习"
                        icon={academicIconUrl}
                        selectedIcon={academicSelectedIconUrl}
                        isSelected={currentMenu === "academic"}
                        onClick={() => setCurrentMenu("academic")}
                    ></MenuItem>
                </Link>
                <Link to={"/qa"}>
                    <MenuItem
                        title="问答"
                        icon={qaIconUrl}
                        selectedIcon={qaSelectedIconUrl}
                        isSelected={currentMenu === "qa"}
                        onClick={() => setCurrentMenu("qa")}
                    ></MenuItem>
                </Link>
                <Link to={"/interview_experience"}>
                    <MenuItem
                        title="面经"
                        icon={interviewIcon}
                        selectedIcon={interviewSelectedIcon}
                        isSelected={currentMenu === "interview_experience"}
                        onClick={() => setCurrentMenu("interview_experience")}
                    ></MenuItem>
                </Link>
                <Link to={"/work_experience"}>
                    <MenuItem
                        title="求职经验"
                        icon={workExIcon}
                        selectedIcon={workSelectedExIcon}
                        isSelected={currentMenu === "work_experience"}
                        onClick={() => setCurrentMenu("work_experience")}
                    ></MenuItem>
                </Link>
                <MenuItem
                    title="关注"
                    icon={followIconUrl}
                    selectedIcon={followSelectedIconUrl}
                    isSelected={currentMenu === "following"}
                    onClick={() => {
                        if(SignInEventEmitter.judgeLogin()){
                            history.push("/following");
                            setCurrentMenu("following")
                        }
                    }}
                ></MenuItem>
                <MenuItem
                    title="消息"
                    icon={messageIconUrl}
                    selectedIcon={messageSelectedIconUrl}
                    isSelected={currentMenu === "message"}
                    onClick={() => {
                        if(SignInEventEmitter.judgeLogin()){
                            history.push("/message");
                            setCurrentMenu("message")
                        }
                    }}
                ></MenuItem>
                <div className={moduleCss.search}>
                    <Input placeholder="标题或内容"
                           defaultValue={searchKey}
                           onChange={(e) => {
                               setSearchKey(e.target.value);
                           }}
                           onKeyUp={(val) => {
                               if (val.key == 'Enter') {
                                   onSearch();
                               }
                           }}/>
                    <img src={searchIconUrl}/>
                </div>
            </div>
            {isSignedIn ? (
                <UserMenu></UserMenu>
            ) : (
                <LoginButtonGroup></LoginButtonGroup>
            )}
        </div>
    );
}

NavBar.propTypes = {
    user: shape({}),
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
