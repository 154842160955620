import {get, post} from "./network";

const communityServicePrefix = "/community";

export async function netArticleDiscovery(param) {
    return get(communityServicePrefix + '/article/v1/op/discovery', param);
}

export async function netArticleByCategory(category, param) {
    return get(communityServicePrefix + `/article/v1/op/${category}`, param);
}

export async function netArticleByAuthor(param) {
    return get(communityServicePrefix + `/article/v1/op/article_by_author`, param);
}

export async function netArticleDetail(param) {
    return get(communityServicePrefix + `/article/v1/op/detail`, param);
}

export async function netReadArticle(param) {
    return post(communityServicePrefix + `/article/v1/op/read`, param);
}

export async function netComment(param) {
    return post(communityServicePrefix + `/article/v1/comment`, param);
}

export async function netCommentList(param) {
    return get(communityServicePrefix + '/article/v1/op/comment_list_by_article', param);
}

export async function netLike(param) {
    return post(communityServicePrefix + '/article/v1/like', param);
}

export async function netUnlike(param) {
    return post(communityServicePrefix + '/article/v1/unlike', param);
}

export async function netCollect(param) {
    return post(communityServicePrefix + '/article/v1/collect', param);
}

export async function netUncollect(param) {
    return post(communityServicePrefix + '/article/v1/uncollect', param);
}
