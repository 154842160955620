import React, {useEffect, useState} from "react";
import "./index.css";
import {Button, Modal} from "antd";
import {bool, func, object} from "prop-types";
import {connect} from "react-redux";
import moment from "moment";
import AvatarImg from "../../../../components/avatar/avatarImg";
import {netFollow, netUnfollow} from "../../../../network/authorNetwork";
import {bindActionCreators} from "redux";
import {followAction} from "../../../../redux/actions/authorAction";
import SignInEventEmitter from "../../../../utils/signInEventEmitter";
import {Link} from "react-router-dom";

function Article({
                     article,
                     followed,
                     followAction
                 }) {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [imgSrc, setImgSrc] = useState('');

    useEffect(()=>{
        let images = document.querySelectorAll(".ql-editor img");

        images.forEach(img => {
            img.style = img.style + ' ;cursor: pointer';
            img.addEventListener('click', (e)=>{
                let imgUri = e.target.currentSrc;
                setImgSrc(imgUri);
                setVisible(true);
            })
        })
    }, [])

    return (
        <div className="article">
            <Modal
                centered
                visible={visible}
                onCancel={() => setVisible(false)}
                width={1000}
                destroyOnClose={true}
                maskClosable={true}
                cancelText={'确定'}
                okButtonProps={{ hidden: true }}
            >
                <img src={imgSrc} width={'100%'}/>
            </Modal>
            <h3 className="article-title">{article.articleTitle}</h3>
            <div className="article-author-block">
                <Link target = "_blank" to={`/author/${article.author.id}`}>
                    <AvatarImg size={48} src={article.author.avatar}></AvatarImg>
                </Link>
                <div className="article-author-info">
                    <div className="article-author">
                        <Link target = "_blank" to={`/author/${article.author.id}`}>
                            <span className="article-author-name">{article.author.nickname}</span>
                        </Link>
                        <Button
                            type="primary"
                            ghost
                            shape="round"
                            size="small"
                            className="follow-btn"
                            loading={loading}
                            onClick={() => {
                                if(!SignInEventEmitter.judgeLogin()){
                                    return;
                                }
                                setLoading(true);
                                if (followed) {
                                    netUnfollow({authorId: article.author.id}).then((res) => {
                                        if (res.status == 'S00') {
                                            followAction(false);
                                        }

                                    }).finally(() => {
                                        setLoading(false);
                                    })
                                } else {
                                    netFollow({authorId: article.author.id}).then((res) => {
                                        if (res.status == 'S00') {
                                            followAction(true);
                                        }
                                    }).finally(() => {
                                        setLoading(false);
                                    })
                                }
                            }}
                        >
                            {followed ? "取消关注" : "关注"}
                        </Button>
                    </div>
                    <div
                        className="article-author-extra">{`${moment.unix(article.createdTime / 1000).format('YYYY.MM.DD HH:mm:ss')}`} 阅读 {article.readCount}</div>
                </div>
            </div>
            <div className="article-content ql-editor" dangerouslySetInnerHTML={{__html: article.content}}></div>
        </div>
    );
}

Article.defaultProps = {};

Article.propTypes = {
    article: object,
    followed: bool,
    followAction: func,
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        followAction: followAction
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        followed: state.authorReducer.followed
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Article);
