export default (state, action) => {
    switch (action.type) {
        case "SET_CURRENT_CATEGORY":
            return {
                ...state,
                category: action.category,
            };
        case "SET_CURRENT_ARTICLE":
            return {
                ...state,
                article: action.article,
            };
        case "CHANGE_ARTICLE_TITLE":
            return {
                ...state,
                changeArticleId: action.articleId,
                changeArticleTitle: action.articleTitle
            }
        default: {
            return {
                category: null,
                article: null
            };
        }
    }
};
