
class DeviceUtil {
    isMobile(){
        let userAgent = navigator.userAgent.toLowerCase();
        let isMobile = userAgent.match(
            /(ipad|iphone os|midp|rv:1.2.3.4|ucweb|android|windows ce| windows mobile )/i
        );
        return isMobile;
    }
    isWxBrowser(){
        let userAgent = navigator.userAgent.toLowerCase();
        if(userAgent.match(/MicroMessenger/i)=="micromessenger"){
            return true;
        }else{
            return false;
        }
    }
}

export default new DeviceUtil();
