export default (state = { user: null }, action) => {
  switch (action.type) {
    case "SIGN_IN_ACTION":
      return {
        ...state,
        user: action.user,
      };
    case "SIGN_OUT_ACTION":
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      return {
        ...state,
        user: null,
      };
    case "UPDATE_USER_ACTION":
      localStorage.setItem("user", JSON.stringify(action.user));
      console.log(action.user);
      return {
        ...state,
        user: action.user,
      };
    default: {
      return state;
    }
  }
};
