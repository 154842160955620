export const signInAction = (user) => ({
    type: 'SIGN_IN_ACTION',
    user: user
})

export const signOutAction = () => ({
    type: 'SIGN_OUT_ACTION'
})

export const updateUserAction = (user) => ({
    type: 'UPDATE_USER_ACTION',
    user: user
})
