import React from 'react';
import 'src/App.css';
import {number, string} from 'prop-types';
import {StyleSpanMarginRight} from '../css/styleTextCard.style'
import {HeartFilled, MessageFilled, EyeFilled} from "@ant-design/icons";

function StyleCardInfo({
                           authorName,
    likeCount,
    commentCount,
    readCount
                       }) {

    return (
        <div>
            <StyleSpanMarginRight>
                {authorName}
            </StyleSpanMarginRight>
            <StyleSpanMarginRight>
                <MessageFilled style={{width: '12px', marginLeft: '12px', marginRight: '4px'}}/>
                {commentCount ?? 0}
            </StyleSpanMarginRight>
            <StyleSpanMarginRight>
                <HeartFilled style={{width: '12px', marginLeft: '12px', marginRight: '4px'}}/>
                {likeCount ?? 0}
            </StyleSpanMarginRight>
            <StyleSpanMarginRight>
                <EyeFilled style={{width: '12px', marginLeft: '12px', marginRight: '4px'}}/>
                {readCount ?? 0}
            </StyleSpanMarginRight>
        </div>
    );
}

StyleCardInfo.propTypes = {
    authorName: string.isRequired,
    likeCount: number,
    commentCount: number,
    readCount: number
}

export default StyleCardInfo;
