import React from 'react';
import {connect} from 'react-redux';
import {array} from 'prop-types';
import 'src/App.css';
import StyleTextCard from "./components/styleTextCard";

function ArticleCard({article}) {
    return (
        <div>
            {article.map(({id, articleTitle, contentPreview, authorName, likeCount, commentCount, readCount, pictureList, priority}) => (
                // eslint-disable-next-line react/jsx-key
                <StyleTextCard
                    key={id}
                    articleId={id}
                    title={articleTitle}
                    shortContent={contentPreview}
                    authorName={authorName}
                    likeCount={likeCount}
                    commentCount={commentCount}
                    readCount={readCount}
                    pictureList={pictureList}
                    priority={priority}
                />
            ))}
        </div>
    );
}

ArticleCard.propTypes = {
    article: array.isRequired,
};

const mapDispatchToProps = () => {
    return {};
};

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleCard);
