import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import 'src/App.css';
import ArticleCard from "../../components/articleCard/articleCard";
import {netArticleByCategory} from "../../network/articleNetwork";
import {Button, Col, DatePicker, Row, Select} from "antd";
import moduleCss from "./interviewExp.story.module.css";
import {netGetDictionaryList} from "../../network/commonNetwork";
import deviceUtil from "../../utils/deviceUtil";
import TagFilter from "../../components/tagFilter/tagFilter";

const { Option } = Select;

function InterviewExpStory() {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNo, setPageNo] = useState(0);
    const [noMorePage, setNoMorePage] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [reasonList, setReasonList] = useState([]);
    let search = false;
    let tagCompany;
    let tagReason;
    let tagYear;
    const [isMobile, setIsMobile] = useState(false);
    let tags = [];
    let refreshByTag = false;
    let didCancel = false;

    useEffect(() => {
        setIsMobile(deviceUtil.isMobile);
    }, []);

    useEffect(()=>{
        let didCancel = false;
        function requestCompanyList (){
            const param = {
                id: 'company'
            }
            netGetDictionaryList(param).then(res => {
                if(res.status=='S00' && res.result){
                    setCompanyList(res.result);
                }
            })
        }
        function requestReasonList (){
            const param = {
                id: 'reason'
            }
            netGetDictionaryList(param).then(res => {
                if(res.status=='S00' && res.result){
                    setReasonList(res.result);
                }
            })
        }
        requestCompanyList();
        requestReasonList();

        return () => {
            didCancel = true;
        };
    }, [setCompanyList, setReasonList])
    function requestArticleList (){
        setLoading(true);
        const param = {
            pageNo: pageNo,
            pageSize: 20,
            company: tagCompany,
            reason: tagReason,
            year: tagYear,
            tags: tags
        }
        netArticleByCategory("01", param).then((res) => {
            if(search){
                setArticles([]);
            }
            if(!didCancel){
                if(res.result){
                    if(search || refreshByTag){
                        setArticles(res.result);
                    }else{
                        setArticles(articles => [...articles, ...res.result]);
                    }

                    if(res.result.length < 20){
                        setNoMorePage(true);
                    }
                }else{
                    setNoMorePage(true);
                    setArticles([]);
                }
            }
        }).finally(()=>{
            if(!didCancel){
                setLoading(false);
            }
            refreshByTag = false;
            search = false;
        })
    }
    useEffect(()=>{
        requestArticleList();

        return ()=>{
            didCancel = true;
        }
    }, [pageNo]);

    return (
        <div className={moduleCss.container}>
            <div className={moduleCss.interviewOptionsContent}>
                {
                    isMobile ? <Col>
                        <Row>
                            <Col span={12}>公司：<Select style={{width: 120}}
                                                      showSearch
                                                      allowClear
                                                      onChange={(e)=>{
                                                          tagCompany=e;
                                                      }}
                                                      placeholder={'请选择公司'}>
                                {companyList ? companyList.map(dict=> (<Option key={dict.id} value={dict.code}>{dict.value}</Option>)) : <></>}
                            </Select></Col>
                            <Col span={12}>原因：<Select style={{width: 120}}
                                                      allowClear
                                                      onChange={(e)=>{
                                                          tagReason=e;
                                                      }}
                                                      placeholder={'请选择原因'}>
                                {reasonList ? reasonList.map(dict=> (<Option key={dict.id} value={dict.code}>{dict.value}</Option>)) : <></>}
                            </Select></Col>
                        </Row>
                        <Row style={{marginTop: 5}}>
                            <Col span={19}>年份：<DatePicker picker="year"
                                                          allowClear
                                                          onChange = {(e, str)=>{
                                                              tagYear=str;
                                                          }}
                                                          placeholder={'请选择年份'}/></Col>
                            <Col span={5}><Button type={"primary"}
                                         className={moduleCss.searchButton}
                                         onClick={()=>{
                                             search = true;
                                             requestArticleList();
                                         }}>搜索</Button></Col>
                        </Row>

                    </Col> : <Row>
                        <Col span={5}>公司：<Select style={{width: 120}}
                                                 showSearch
                                                 allowClear
                                                 onChange={(e)=>{
                                                     tagCompany=e;
                                                 }}
                                                 placeholder={'请选择公司'}>
                            {companyList ? companyList.map(dict=> (<Option key={dict.id} value={dict.code}>{dict.value}</Option>)) : <></>}
                        </Select></Col>
                        <Col span={5}>原因：<Select style={{width: 120}}
                                                 allowClear
                                                 onChange={(e)=>{
                                                     tagReason=e;
                                                 }}
                                                 placeholder={'请选择原因'}>
                            {reasonList ? reasonList.map(dict=> (<Option key={dict.id} value={dict.code}>{dict.value}</Option>)) : <></>}
                        </Select></Col>
                        <Col span={5}>年份：<DatePicker picker="year"
                                                     allowClear
                                                     onChange = {(e, str)=>{
                                                         tagYear=str;
                                                     }}
                                                     placeholder={'请选择年份'}/></Col>
                        <Col><Button type={"primary"}
                                     className={moduleCss.searchButton}
                                     onClick={()=>{
                                         search = true;
                                         requestArticleList();
                                     }}>搜索</Button></Col>
                    </Row>
                }
            </div>
            <TagFilter
                onChange={(itemList)=>{
                    tags = itemList;
                    setPageNo(0);
                    refreshByTag = true;
                    requestArticleList();
                }}
            ></TagFilter>
            <ArticleCard
                article={articles}
            />
            <Button
                className={moduleCss.loadingMoreButton}
                type={"link"}
                disabled={noMorePage}
                loading={loading}
                onClick={()=>{
                setPageNo(pageNo => pageNo + 1);
            }}>{noMorePage ? '没有更多内容了～' : '加载更多'}</Button>
        </div>
    );
}

InterviewExpStory.defaultProps = {
}

InterviewExpStory.propTypes = {
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InterviewExpStory);
