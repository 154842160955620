import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Tag} from 'antd';
import {array, func, string} from "prop-types";
import {netGetDictionaryList} from "../../network/commonNetwork";
import moduleCss from "./tagFilter.module.css";

function TagFilter({
                         tags,
                         onChange
                     }) {
    const [options, setOptions] = useState([]);
    const [selectedTag, setSelectedTag] = useState([]);

    useEffect(() => {
        let didCancel = false;

        function requestTagList() {
            const param = {
                id: "article-tag",
            };
            netGetDictionaryList(param).then((res) => {
                if (res.status == "S00" && res.result && !didCancel) {
                    setOptions(res.result.map(item => {
                        return {
                            value: item.value,
                            selected: false
                        }
                    }));
                }
            });
        }

        requestTagList();

        return () => {
            didCancel = true;
        };
    }, [setOptions]);

    const onClick = (tag) => {
        tag.selected = !tag.selected;
        if(tag.selected){
            selectedTag.push(tag);
            setSelectedTag(selectedTag);
        }else{
            const temp = selectedTag.filter(item => item.value != tag.value);
            setSelectedTag(temp);
        }

        onChange(options.filter(item => item.selected).map(item => item.value));
    }

    return (
        <div className={moduleCss.container}>
            {
                options.map((item, index) => (
                    <Tag
                        className={moduleCss.tag}
                        onClick={()=>{onClick(item)}}
                        color={
                        item.selected ? "orange" : "default"
                    }
                         key={index}
                    >
                        {item.value}
                    </Tag>
                ))
            }
        </div>
    )
}

TagFilter.defaultProps = {};

TagFilter.propTypes = {
    tags: array,
    onChange: func
};

const mapDispatchToProps = () => {
    return {};
};

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(TagFilter);
