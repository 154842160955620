import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "antd/dist/antd.min.css";
import "src/App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { func, shape, string } from "prop-types";
import { bindActionCreators } from "redux";
import { signInAction } from "./redux/actions/userAction";
import { IntlProvider } from "react-intl";
import en_US from "./locale/en_US";
import zh_CN from "./locale/zh_CN";
import { changeLocalAction } from "./redux/actions/localeAction";

import routes from "./routes";
import deviceUtil from "./utils/deviceUtil";

function App({ localeLang, signInAction, user }) {
  let messages = {};
  messages["en"] = en_US;
  messages["zh"] = zh_CN;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(deviceUtil.isMobile);
  }, []);

  useEffect(() => {
    const userStr = localStorage.getItem("user");
    const savedUser = JSON.parse(userStr);
    if (savedUser) {
      signInAction(savedUser);
    }
  }, [signInAction]);

  return(
    <IntlProvider locale={localeLang} messages={messages[localeLang]}>
      <Router>
        <Switch>
          {routes.map((route, key) => {
            if (route.exact) {
              return (
                <Route
                  key={key}
                  exact
                  path={route.path}
                  component={(props) => {
                    return <route.component {...props} routes={route.routes} />;
                  }}
                ></Route>
              );
            } else {
              return (
                <Route
                  key={key}
                  path={route.path}
                  component={(props) => {
                    return <route.component {...props} routes={route.routes} />;
                  }}
                ></Route>
              );
            }
          })}
        </Switch>
      </Router>
    </IntlProvider>
  );
}

App.defaultProps = {};

App.propTypes = {
  signInAction: func,
  user: shape({}),
  localeLang: string,
  changeLocalAction: func,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signInAction: signInAction,
      changeLocalAction: changeLocalAction,
    },
    dispatch
  );
};

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    localeLang: state.localeReducer.lang,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
