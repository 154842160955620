import React from "react";
import {connect} from "react-redux";
import moduleCss from "./likedArticleCard.module.css";
import {HeartFilled, MessageFilled} from "@ant-design/icons";
import {shape} from "prop-types";
import {Link} from "react-router-dom";

function LikedArticleCard({article}) {
    return (
        <div className={moduleCss.container}>
            <div className={moduleCss.title}>
                <Link to={`/detail/${article.id}`}>{article.articleTitle}</Link>
            </div>
            <div className={moduleCss.content}>
                {article.contentPreview}
            </div>
            <div className={moduleCss.articleInfo}>
                <div className={moduleCss.author}>{article.authorName}</div>
                <div className={moduleCss.comment}>
                    <MessageFilled size={11} style={{color:"#979797"}} />
                    <span className={moduleCss.commentLikeNumber}>{article.likeCount}</span>
                </div>
                <div className={moduleCss.like}>
                    <HeartFilled style={{width: "11px", color:"#979797"}}/>
                    <span className={moduleCss.commentLikeNumber}>{article.commentCount}</span>
                </div>
            </div>
        </div>
    )
}

LikedArticleCard.propTypes = {
    article: shape({}),
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LikedArticleCard);
