import {connect} from "react-redux";
import React from "react";
import moduleCss from "./followingArticleCard.module.css";
import {HeartFilled, MessageFilled} from "@ant-design/icons";
import {shape} from "prop-types";
import AvatarImg from "../../../../components/avatar/avatarImg";
import moment from "moment";
import {Link} from "react-router-dom";

function FollowingArticleCard({
    article
                              }){
    return (
        <div className={moduleCss.container}>
            <div className={moduleCss.followerInfo}>
                <AvatarImg src={article.authorAvatar} size={24}></AvatarImg>
                <span className={moduleCss.nickname}>{article.authorName}</span>
                {/*<span className={moduleCss.nickname}>Alisa</span>*/}
                {/*<span className={moduleCss.likeTime}>喜欢了文章 . 2小时前</span>*/}
            </div>
            <Link to={`/detail/${article.id}`}>
                <div className={moduleCss.title}>{article.articleTitle}</div>
            </Link>
            <div className={moduleCss.content}>{article.contentPreview}</div>
            <div className={moduleCss.articleInfo}>
                <span className={moduleCss.articleAuthor}>{moment.unix(article.createdTime / 1000).format('YYYY.MM.DD HH:mm:ss')}</span>
                <div className={moduleCss.comment}>
                    <MessageFilled size={11} style={{color:"#979797"}} />
                    <span className={moduleCss.commentLikeNumber}>{article.commentCount ?? 0}</span>
                </div>
                <div className={moduleCss.like}>
                    <HeartFilled style={{width: "11px", color:"#979797"}}/>
                    <span className={moduleCss.commentLikeNumber}>{article.likeCount ?? 0}</span>
                </div>
            </div>
        </div>
    );
}

FollowingArticleCard.defaultProps = {
}

FollowingArticleCard.propTypes = {
    article: shape({})
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FollowingArticleCard);
