import React from "react";
import {number, string} from "prop-types";
import {connect} from "react-redux";
import {Avatar} from 'antd';
import {UserOutlined} from "@ant-design/icons";
import {HOST} from "../../utils/config";

function AvatarImg({
                       size,
                       src = ""
                   }) {
    console.log(src);
    return (
        src && src != null ? <Avatar
                size={size}
                icon={<UserOutlined/>}
                src={ !size ?
                    `${HOST.image}/` + src
                    : `${HOST.image}/` + src+ `?x-oss-process=image/resize,m_fill,h_${size * 2},w_${size * 2}`}/>
            : <Avatar
                size={size}
                icon={<UserOutlined/>}/>

    )
}

AvatarImg.defaultProps = {};

AvatarImg.propTypes = {
    src: string,
    size: number,
};

const mapDispatchToProps = () => {
    return {};
};

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AvatarImg);
