import styled from 'styled-components';

const ShortTextCardContainer = styled.div`
  @media (min-width: 768px) {
    width: 960px;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 15px;
  }
`

const ShortTitle = styled.div`
  font-size: 18px;
  width: 100%;
  color: #3C3C3C;
  font-weight: bold;
  -webkit-box-orient: vertical;
  padding-bottom: 10px;
  padding-top: 10px;
  :hover {
    color: #427DFF;
  }
`

const ShortContent = styled.div`
  font-size: 15px;
  color: #999A9A;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

const ShortContentContainer = styled.div`
  display: flex;
`

const ShortContentImg = styled.img`
  height: 100px;
  width: 150px;
  margin-left: 30px;
`

const StyleSpanMarginRight = styled.span`
  padding-right: 6px;
  color: #B4B4B4;
  font-size: 12px;
`

const EndingLine = styled.div`
  border: 0;
  background-color: #F1F1F0;
  height: 1px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export {
    ShortTitle,
    ShortContent,
    ShortTextCardContainer,
    StyleSpanMarginRight,
    EndingLine,
    ShortContentImg,
    ShortContentContainer
};
