import JSEncrypt from 'jsencrypt';

export function rsaEncrypt(txt: string): string {
    const PUBLIC_KEY = '-----BEGIN PUBLIC KEY-----\n' +
        'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC23hyxRw7FrBXEixuutEjmS9s5\n' +
        'w4q5Itc/GoeQX8HE9WGNyH/lmqN+zL0+qV7V64YpknO9ABUU9+7uv9b501G3KRBu\n' +
        'YgF7CIl36enSmlWd0XntyZHkrW8kdS3rE3dWYBJ9yV1g+/MeK6PAPYdf/aKk7xz8\n' +
        '728lIaGjSHbo1bL6gwIDAQAB\n' +
        '-----END PUBLIC KEY-----';
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUBLIC_KEY);
    return encodeURI(encrypt.encrypt(txt));
}
