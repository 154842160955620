import React from "react";
import {connect} from "react-redux";
import moduleCss from "./authorArticleCard.module.css";
import {HeartFilled, MessageFilled} from "@ant-design/icons";
import {shape} from "prop-types";
import {Link} from "react-router-dom";
import moment from "moment";

function AuthorArticleCard({article}) {
    return (
        <div className={moduleCss.container}>
            <div className={moduleCss.title}>
                <Link to={'/detail/' + article.id}>{article.articleTitle}</Link>
            </div>
            <div className={moduleCss.content}>
                {article.contentPreview}
            </div>
            <div className={moduleCss.articleInfo}>
                <div className={moduleCss.comment}>
                    <MessageFilled size={11} style={{color:"#979797"}} />
                    <span className={moduleCss.commentLikeNumber}>{article.commentCount ?? 0}</span>
                </div>
                <div className={moduleCss.like}>
                    <HeartFilled style={{width: "11px", color:"#979797"}}/>
                    <span className={moduleCss.commentLikeNumber}>{article.likeCount ?? 0}</span>
                </div>
                <div className={moduleCss.createdTime}>{moment.unix(article.createdTime / 1000).format('YYYY.MM.DD HH:mm:ss')}</div>
            </div>
        </div>
    )
}

AuthorArticleCard.propTypes = {
    article: shape({}),
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AuthorArticleCard);
