import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Button, Skeleton, Tabs} from "antd";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import AuthorFollowerCard from "./components/authorFollowerCard/authorFollowerCard";
import {shape, string} from "prop-types";
import {netFansList, netFollowingList} from "../../../../network/authorNetwork";
import moduleCss from "./authorFollower.module.css";
import AuthorArticleCard from "../authorArticle/components/authorArticleCard/authorArticleCard";

function AuthorFollower(
    {
        currentTab,
        author
    }
) {

    const [followingPage, setFollowingPage] = useState(0);
    const [fansPage, setFansPage] = useState(0);
    const [followingList, setFollowingList] = useState([]);
    const [fansList, setFansList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [followingNoMorePage, setFollowingNoMorePage] = useState(false);
    const [fansNoMorePage, setFansNoMorePage] = useState(false);

    useEffect(() => {
        if (!author.id) {
            return;
        }
        let didCancel = false;
        setLoading(true);
        const param = {
            pageNo: followingPage,
            pageSize: 20
        }
        netFollowingList(author.id, param).then(res => {
            if (res.result && !didCancel) {
                setFollowingList(following => [...following, ...res.result]);
                if (res.result.length < 20) {
                    setFollowingNoMorePage(true);
                }
            }
        }).finally(() => {
            if (!didCancel) {
                setLoading(false)
            }
        })
        return () => {
            didCancel = true
        }
    }, [author.id, followingPage])

    useEffect(() => {
        if (!author.id) {
            return;
        }
        let didCancel = false;
        setLoading(true);
        const param = {
            pageNo: fansPage,
            pageSize: 20
        }
        netFansList(author.id, param).then(res => {
            if (res.result && !didCancel) {
                setFansList(fan => [...fan, ...res.result]);
                if (res.result.length < 20) {
                    setFansNoMorePage(true);
                }
            }
        }).finally(() => {
            if (!didCancel) {
                setLoading(false)
            }
        })
        return () => {
            didCancel = true
        }
    }, [author.id, fansPage])

    const history = useHistory();

    const onTabClick = (key) => {
        history.push(`/author/${author.id}/` + key);
    }

    return (
        <div>
            <Tabs activeKey={currentTab} onTabClick={onTabClick}>
                <Tabs.TabPane
                    tab={
                        <span>
                          关注用户
                        </span>
                    }
                    key="following"
                >
                    <div className={moduleCss.container}>
                        {
                            followingList.length == 0 && loading ?
                                <Skeleton active></Skeleton>
                                :
                                followingList.map(follower => {
                                    return <AuthorFollowerCard key={follower.id} follower={follower}/>
                                })

                        }
                        {
                            followingList.length > 0 ?
                                <Button
                                    className={moduleCss.loadingMoreButton}
                                    type={"link"}
                                    loading={loading}
                                    disabled={followingNoMorePage}
                                    onClick={() => {
                                        setFollowingPage(pageNo => pageNo + 1)
                                    }}>{followingNoMorePage ? '没有更多内容了～' : '加载更多'}</Button> : <></>
                        }
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={
                        <span>
                          粉丝
                        </span>
                    }
                    key="follower"
                >
                    <div className={moduleCss.container}>
                        {
                            followingList.length == 0 && loading ?
                                <Skeleton active></Skeleton>
                                :
                                fansList.map(fan => {
                                    return <AuthorFollowerCard key={fan.id} follower={fan}/>
                                })

                        }
                        {
                            followingList.length > 0 ?
                                <Button
                                    className={moduleCss.loadingMoreButton}
                                    type={"link"}
                                    loading={loading}
                                    disabled={fansNoMorePage}
                                    onClick={() => {
                                        setFansPage(pageNo => pageNo + 1)
                                    }}>{fansNoMorePage ? '没有更多内容了～' : '加载更多'}</Button> : <></>
                        }
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}

AuthorFollower.propTypes = {
    currentTab: string,
    author: shape({})
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AuthorFollower);
