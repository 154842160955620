import {get, post} from "./network";

const userServicePrefix = "/user";

export async function netSignIn(params) {
    return post(userServicePrefix + '/user/v2/op/login', params);
}

export async function netUpdateInfo(param) {
    return post(userServicePrefix + '/user/v1/update-info', param);
}

export async function netBasicInfo() {
    return get(userServicePrefix + '/user/v1/basic-info');
}

export async function netGetVerifyCode(email, param) {
    return get(userServicePrefix + '/user/v1/op/verify-code/' + email, param);
}

export async function netResetPassword(param) {
    return post(userServicePrefix + '/user/v2/op/reset-password', param);
}

export async function netRegistry(param) {
    return post(userServicePrefix + '/user/v2/op/registry', param);
}
