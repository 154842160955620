import { Quill } from "react-quill";

var BlockEmbed = Quill.import("blots/block/embed");
var InlineBlot = Quill.import("blots/inline");

export class ImageBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    node.setAttribute("src", value.url);
    node.setAttribute("alt", value.alt);
    node.setAttribute("crossOrigin", "anonymous");
    node.setAttribute("width", value.width);
    return node;
  }

  static value(node) {
    return {
      url: node.getAttribute("src"),
      alt: node.getAttribute("alt"),
      width: node.getAttribute("width"),
      height: node.getAttribute("height"),
    };
  }
}

ImageBlot.blotName = "image";
ImageBlot.tagName = "img";

export class LinkBlot extends InlineBlot {
  static create(value) {
    let node = super.create();
    node.setAttribute("href", value.href || value);
    node.setAttribute("target", "_blank");
    node.setAttribute("target", "_blank");
    node.setAttribute("target", "_blank");
    if (typeof value === "object") {
      node.setAttribute("title", value.text || value || "");
      node.innerText = value.text || value || "";
    } else {
      node.setAttribute("title", node.innerText.trim());
    }

    return node;
  }

  static formats(domNode) {
    return domNode.getAttribute("href") || true;
  }

  format(name, value) {
    if (name === "link" && value) {
      this.domNode.setAttribute("href", value);
    } else {
      super.format(name, value);
    }
  }

  formats() {
    let formats = super.formats();
    formats["link"] = LinkBlot.formats(this.domNode);
    return formats;
  }
}
LinkBlot.blotName = "link";
LinkBlot.tagName = "A";
