import {
  createStore,
  applyMiddleware,
} from 'redux';
import rootReducer from 'src/redux/reducers/rootReducer';

export default function configureStore(initialState= {}) {
  return createStore(
    rootReducer,
    initialState,
    applyMiddleware()
  );
}
