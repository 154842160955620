import React, {useState} from "react";
import {connect} from "react-redux";
import moduleCss from "./setting.story.module.css"
import SettingMenu from "./components/settingMenu/settingMenu";
import SettingBasic from "./components/settingBasic/settingBasic";

function SettingStory() {

    const [currentMenu] = useState("basic");

    return (
        <div className={moduleCss.container}>
            <div className={moduleCss.menuList}>
                <SettingMenu currentMenu={currentMenu}></SettingMenu>
            </div>
            <div className={moduleCss.settingPage}>
                <SettingBasic></SettingBasic>
            </div>
        </div>
    )
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingStory);
