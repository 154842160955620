import React, {useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import moduleCss from "./signUp.module.css";
import {Button, Input, message} from "antd";
import {signInAction} from "../../../../redux/actions/userAction";
import {func} from "prop-types";
import {
    netGetVerifyCode,
    netRegistry,
    netResetPassword,
    netUpdateInfo,
} from "../../../../network/userNetwork";
import {rsaEncrypt} from "../../../../utils/rsaEncrypt";

function SignUp({signInAction}) {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [codeLoading, setCodeLoading] = useState(false);
    const [countSeconds, setCountSeconds] = useState(60);
    const [counting, setCounting] = useState(false);
    const [verifyCode, setVerifyCode] = useState("");
    let seconds = 60;

    const onCountDown = () => {
        if (seconds == 0) {
            setCounting(false);
            setCountSeconds(60);
            seconds = 60;
        } else {
            seconds = seconds - 1;
            setCountSeconds(seconds);
            setTimeout(() => {
                onCountDown();
            }, 1000);
        }
    };

    const onGetEmailCode = () => {
        setCodeLoading(true);
        const param = {
            type: "registry",
        };
        netGetVerifyCode(email, param)
            .then((res) => {
                if (res.status == "S00") {
                    setCounting(true);
                    onCountDown();
                    message.success("验证码已发送，请注意查收");
                }
            })
            .finally(() => {
                setCodeLoading(false);
            });
    };

    const onSignUp = () => {
        if (verifyCode.length == 0) {
            message.info("请输入验证码");
            return;
        }
        if (!email) {
            message.info("请输入邮箱");
            return;
        }
        if (!password) {
            message.info("请输入密码");
            return;
        }
        setLoading(true);
        const param = {
            email: email,
            code: verifyCode,
            password: rsaEncrypt(password),
        };
        netRegistry(param)
            .then((res) => {
                if (res.status == "S00") {
                    message.success("注册成功");
                    const token = res.result.token;
                    localStorage.setItem("token", token);
                    let user = {
                        id: res.result.id,
                        avatar: res.result.avatar,
                        career: res.result.career,
                        email: res.result.email,
                        nickName: res.result.nickName,
                        username: res.result.username,
                    };
                    localStorage.setItem("user", JSON.stringify(user));
                    signInAction(user);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className={moduleCss.container}>
            <div className={moduleCss.title}>注册</div>
            <Input className={`${moduleCss.input} ${moduleCss.username}`}
                   placeholder="邮箱"
                   onChange={(e) => {
                       setEmail(e.target.value);
                   }}
            />
            <Input.Password className={`${moduleCss.input} ${moduleCss.password}`}
                            placeholder="密码"
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
            />
            <div className={moduleCss.authContainer}>
                <Input className={`${moduleCss.input} ${moduleCss.authCode}`}
                       placeholder="验证码"
                       onChange={(e) => {
                           setVerifyCode(e.target.value);
                       }}
                />
                <Button className={moduleCss.authCodeBtn}
                        type={"primary"}
                        disabled={counting}
                        loading={codeLoading}
                        onClick={() => {
                            onGetEmailCode();
                        }}
                >{counting ? `${countSeconds}秒后重新获取` : `获取验证码`}</Button>
            </div>
            <Button type={"primary"}
                    className={moduleCss.signBtn}
                    loading={loading}
                    onClick={() => {
                        onSignUp();
                    }}>注册</Button>
        </div>
    )
}

SignUp.propTypes = {
    signInAction: func,
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            signInAction: signInAction,
        },
        dispatch
    );
};

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
