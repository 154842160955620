import { combineReducers } from 'redux';
import selectFollowingReducer from "./selectFollowingReducer";
import userReducer from "./userReducer";
import localeReducer from "./localeReducer";
import authorReducer from "./authorReducer";
import writingReducer from "./writingReducer";

export default combineReducers({
    selectFollowingReducer,
    userReducer,
    localeReducer,
    authorReducer,
    writingReducer
});
