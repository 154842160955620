import React, {useState} from "react";
import {connect} from "react-redux";
import {Avatar, Button} from "antd";
import moduleCss from "./authorFollowerCard.module.css";
import {CheckOutlined, PlusOutlined} from "@ant-design/icons";
import {shape} from "prop-types";
import AvatarImg from "../../../../../../components/avatar/avatarImg";
import {netFollow, netUnfollow} from "../../../../../../network/authorNetwork";

function AuthorFollowerCard(
    {follower}
) {
    const [followed, setFollowed] = useState(follower.followed);
    const [loading, setLoading] = useState(false);
    return (
        <div className={moduleCss.container}>
            <div className={moduleCss.authorInfo}>
                <AvatarImg size={50} src={follower.avatar}></AvatarImg>
                <div className={moduleCss.textInfo}>
                    <div className={moduleCss.nickname}>{follower.nickname}</div>
                    <div className={moduleCss.briefInfo}>关注{follower.followCount ?? 0} ｜ 粉丝{follower.fansCount ?? 0} ｜ 文章{follower.articleCount ?? 0}</div>
                    <div className={moduleCss.likeCount}>获得喜欢 {follower.likeCount ?? 0}</div>
                </div>
            </div>
            <div>
                {
                    followed ?
                        <Button className={`${moduleCss.followingBtn} ${moduleCss.followed}`}
                                loading={loading}
                                onClick={()=>{
                                    setLoading(true);
                                    netUnfollow({authorId: follower.id}).then((res) => {
                                        if (res.status == 'S00') {
                                            setFollowed(false);
                                        }

                                    }).finally(() => {
                                        setLoading(false);
                                    })
                                }}
                        ><CheckOutlined />已关注</Button>
                        :
                        <Button className={`${moduleCss.followerBtn}`}
                                type={"primary"}
                                onClick={()=>{
                                    setLoading(true);
                                    netFollow({authorId: follower.id}).then((res) => {
                                        if (res.status == 'S00') {
                                            setFollowed(true);
                                        }

                                    }).finally(() => {
                                        setLoading(false);
                                    })
                                }}
                        ><PlusOutlined />关注</Button>
                }
            </div>
        </div>
    )
}

AuthorFollowerCard.propTypes = {
    follower: shape({}),
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AuthorFollowerCard);
