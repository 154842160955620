class PathParamUtil {
    getPathParam(){
        let url = window.location.href;
        if(url.indexOf('?') != -1){
            let obj = {};
            let arr = url.slice(url.indexOf('?')+1).split('&');
            arr.forEach(item=>{
                let param = item.split('=');
                obj[param[0]] = param[1];
            })
            return obj;
        }else{
            return null;
        }
    }

}

export default new PathParamUtil();
