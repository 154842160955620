import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Dropdown, Menu} from "antd";
import {
    CaretDownOutlined,
    EditOutlined,
    HeartOutlined,
    LogoutOutlined,
    QuestionCircleOutlined,
    SettingOutlined,
    StarOutlined,
    UserOutlined,
} from "@ant-design/icons";
import moduleCss from "./userMenu.module.css";
import {Link} from "react-router-dom";
import {
    signInAction,
    signOutAction,
} from "../../../../redux/actions/userAction";
import {func, shape, string} from "prop-types";
import AvatarImg from "../../../avatar/avatarImg";
import SignOutEventEmitter from "../../../../utils/signOutEventEmitter";
import deviceUtil from "../../../../utils/deviceUtil";

function UserMenu({signOutAction, user}) {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(deviceUtil.isMobile);
    }, []);
    useEffect(() => {
        SignOutEventEmitter.addListener("SIGN_OUT", () => {
            signOutAction();
        });
    }, [signOutAction]);

    useEffect(() => {
        SignOutEventEmitter.removeListener("SIGN_OUT", () => {
        });
    }, []);

    const menu = (
        <Menu>
            <Menu.Item key={"author"}>
                <Link to={`/author/${user.id}`}>
                    <Button
                        type={"link"}
                        icon={<UserOutlined className="menu-icon"/>}
                        className={moduleCss.userMenuButton}
                    >
                        我的主页
                    </Button>
                </Link>
            </Menu.Item>
            <Menu.Item key={"collection"}>
                <Link to={`/author/${user.id}/collected_article`}>
                    <Button
                        type={"link"}
                        icon={<StarOutlined className="menu-icon"/>}
                        className={moduleCss.userMenuButton}
                    >
                        收藏的文章
                    </Button>
                </Link>
            </Menu.Item>
            <Menu.Item key={"liked"}>
                <Link to={`/author/${user.id}/liked_article`}>
                    <Button
                        type={"link"}
                        icon={<HeartOutlined className="menu-icon"/>}
                        className={moduleCss.userMenuButton}
                    >
                        喜欢的文章
                    </Button>
                </Link>
            </Menu.Item>
            <Menu.Item key={"setting"}>
                <Link to={"/setting"}>
                    <Button
                        type={"link"}
                        icon={<SettingOutlined className="menu-icon"/>}
                        className={moduleCss.userMenuButton}
                    >
                        设置
                    </Button>
                </Link>
            </Menu.Item>
            <Menu.Item key={"feedback"}>
                <Link to={"/feedback"}>
                    <Button
                        type={"link"}
                        icon={<QuestionCircleOutlined className="menu-icon"/>}
                        className={moduleCss.userMenuButton}
                    >
                        帮组与反馈
                    </Button>
                </Link>
            </Menu.Item>
            <Menu.Item key={"sign-out"}>
                <Button
                    type={"link"}
                    icon={<LogoutOutlined className="menu-icon"/>}
                    className={moduleCss.userMenuButton}
                    onClick={() => {
                        signOutAction();
                        window.location.href = window.location.origin;
                    }}
                >
                    退出
                </Button>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className={moduleCss.user}>
            {
                isMobile ?  <Dropdown overlay={menu}>
                    <div className={moduleCss.avatar}>
                        <AvatarImg size={40} src={user.avatar}></AvatarImg>
                        <CaretDownOutlined style={{color: "#999898"}}/>
                    </div>
                </Dropdown> :  <Dropdown overlay={menu}>
                    <div className={moduleCss.avatar}>
                        <Link to={`/author/${user.id}`}>
                            <AvatarImg size={40} src={user.avatar}></AvatarImg>
                        </Link>
                        <CaretDownOutlined style={{color: "#999898"}}/>
                    </div>
                </Dropdown>
            }
            {
                !isMobile ? <Link target={"_blank"} to={"/writing"}>
                    <Button
                        type="primary"
                        icon={
                            <EditOutlined style={{fontSize: "16px", fontWeight: "bold"}}/>
                        }
                        shape="round"
                        style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            height: "40px",
                            marginLeft: "10px",
                        }}
                    >
                        创作
                    </Button>
                </Link> : <></>
            }
        </div>
    );
}

UserMenu.propTypes = {
    signOutAction: func,
    user: shape({}),
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            signOutAction: signOutAction,
            signInAction: signInAction,
        },
        dispatch
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
