import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import moduleCss from "./authorIntro.module.css"
import {Button} from "antd";
import {EditOutlined, HeartOutlined, StarOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import {Link} from "react-router-dom";
import {shape} from "prop-types";
import {netUpdateInfo} from "../../../../network/userNetwork";

function AuthorIntro({author}) {
    const [isEditIntroduce, setIsEditIntroduce] = useState(false);
    const [loading, setLoading] = useState(false);
    let newIntro = author.description;
    const onChangeIntroduce = () => {
        setIsEditIntroduce(!isEditIntroduce);
    }

    const onSaveIntro = () => {
        setLoading(true);
        netUpdateInfo({description: newIntro}).then(res => {
            if(res){
                author.description = newIntro;
                setIsEditIntroduce(!isEditIntroduce);
            }
        }).finally(()=>{
            setLoading(false);
        })
    }

    return (
        author ?
        <div className={moduleCss.container}>
            <div className={moduleCss.introduce}>
                <div className={moduleCss.header}>
                    <div>个人介绍</div>
                    <EditOutlined onClick={()=>{onChangeIntroduce()}}  style={{color:"#999A9A"}}/>
                </div>
                {
                    isEditIntroduce ?
                        <div>
                            <TextArea className={moduleCss.introTextArea}
                                      rows={4}
                                      defaultValue={author.description}
                                      onChange={(e) => {
                                          newIntro = e.target.value;
                                      }}
                            ></TextArea>
                            <div>
                                <Button className={moduleCss.saveBtn}
                                        loading={loading}
                                        onClick={()=>{onSaveIntro()}}
                                >保存</Button>
                                <Button type={"link"}
                                        className={moduleCss.cancelBtn}
                                        onClick={()=>{onChangeIntroduce()}}
                                >取消</Button>
                            </div>
                        </div>
                        :
                        <div>{author?.description ?? '好懒啊，这家伙什么都说～'}</div>
                }
            </div>
            <div className={moduleCss.linkContainer}>
                <Link to={{pathname: `/author/${author.id}/liked_article`, state:{tab:"liked"}}}>
                    <Button type={"text"} className={moduleCss.linkItem}>
                        <HeartOutlined style={{fontSize:"20px"}}/>
                        <span style={{marginLeft:"16px"}}>我喜欢的文章</span>
                    </Button>
                </Link>
                <Link to={{pathname: `/author/${author.id}/collected_article`, state:{tab:"collected"}}}>
                    <Button type={"text"} className={moduleCss.linkItem}>
                        <StarOutlined style={{fontSize:"20px"}}/>
                        <span style={{marginLeft:"16px"}}>我收藏的文章</span>
                    </Button>
                </Link>
            </div>
        </div> : <></>
    )
}

AuthorIntro.defaultProps = {}

AuthorIntro.propTypes = {
    author: shape({}),
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AuthorIntro);
