export default (state = { followed: false }, action) => {
  switch (action.type) {
    case "FOLLOW_ACTION":
      return {
        ...state,
        followed: action.followed,
      };
    default: {
      return state;
    }
  }
};
