import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import styles from "./writing.story.module.css";
import WritingMenu from "./components/WritingMenu";
import WriteArticle from "./components/WriteArticle";
import Articles from "./components/Articles";
import {object} from "prop-types";
import {LeftCircleOutlined, RightCircleOutlined} from "@ant-design/icons";

function WritingStory({match}) {
    const [hideArticles, setHideArticles] = useState(false);
    return (
        <div className={styles.writing}>
            <div className={styles.writingMenu}>
                <WritingMenu/>
            </div>
            <div className={hideArticles ? styles.writingArticlesHidden : styles.writingArticles}>
                <Articles hideContent={hideArticles}/>
                <div className={styles.showHideButton} onClick={()=>{
                    setHideArticles(!hideArticles);
                }}>
                    {
                        hideArticles ? <RightCircleOutlined style={{fontSize: '20px', color: 'lightgray'}}/> :
                            <LeftCircleOutlined style={{fontSize: '20px', color: 'lightgray'}}/>
                    }
                </div>
            </div>
            <div className={styles.writingContent}>
                <WriteArticle/>
            </div>
        </div>
    );
}

WritingStory.defaultProps = {};

WritingStory.propTypes = {
    match: object,
};

const mapDispatchToProps = () => {
    return {};
};

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(WritingStory);
