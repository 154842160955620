import React, {useEffect, useState} from "react";
import {FileOutlined, DeleteOutlined} from "@ant-design/icons";
import styles from "./index.module.css";
import {PlusCircleFilled} from "@ant-design/icons";
import {array, bool, func, number, object, string} from "prop-types";
import moment from "moment";
import {Empty, message, Popconfirm} from "antd";
import {netDeleteArticle, netGetArticleDraft} from "../../../../network/writingNetwork";
import {bindActionCreators} from "redux";
import {setCurrentArticle, setCurrentCategory} from "../../../../redux/actions/writingAction";
import {connect} from "react-redux";

const Articles = ({
                      setCurrentArticle,
                      category,
                      article,
                      hideContent
                  } = {}) => {

    const [articles, setArticles] = useState([]);

    useEffect(() => {
        if (category) {
            const param = {
                categoryId: category.id
            }
            netGetArticleDraft(param).then(res => {
                if (res.status == 'S00') {
                    setArticles(res.result ?? []);
                }
            })
        }
    }, [category]);

    return (
            <div className={styles.container} style={hideContent ? {display: 'none'} : {display: 'block'}}>
                <div
                    className={styles.newArticle}
                    onClick={() => {
                        const newArticle = {
                            id: ((new Date()).valueOf()).toString(),
                            articleTitle: '',
                            content: ''
                        }
                        if(articles){
                            articles.unshift(newArticle);
                        }else{
                            articles.push(newArticle);
                        }

                        setArticles(articles);
                        setCurrentArticle(articles[0]);
                    }}
                >
                    <PlusCircleFilled size={16}/>
                    新建文章
                </div>
                <div className={styles.articleList}>
                    {articles && articles.length > 0 ? articles.map((item) => (
                        <div
                            key={item.id}
                            className={`${styles.articleBlock} ${
                                article?.id === item.id ? styles.current : ""
                            }`}
                            onClick={() =>
                                article?.id === item.id ? "" : setCurrentArticle(item)
                            }
                        >
                            <FileOutlined
                                style={{fontSize: "30px", color: "#BEBEBE", padding: "14px"}}
                            />
                            <div className={styles.articleTitle}>
                                <div className={styles.articleTitleText}>{item.articleTitle}</div>
                                {/*<div className={styles.articleUpdateAt}>{moment.unix(item.modifiedTime / 1000).format('YYYY.MM.DD HH:mm:ss')}</div>*/}
                            </div>
                            <Popconfirm
                                title="确定要删除么?"
                                onConfirm={()=>{
                                    netDeleteArticle({id: item.id}).then(res => {
                                        if(res.status == 'S00'){
                                            message.success('已删除');
                                            const remainList = articles.filter(data => {
                                                return data.id != item.id
                                            });
                                            setArticles(remainList);
                                        }
                                    })
                                }}
                                okText="确定"
                                cancelText="取消"
                            >
                                <a href="#"><DeleteOutlined
                                    style={{fontSize: "14px", color: "#BDBDBD", padding: "20px"}}
                                /></a>
                            </Popconfirm>
                        </div>
                    )) : <Empty description={false}/>}
                </div>
            </div>
    );
};

Articles.defaultProps = {}

Articles.propTypes = {
    setCurrentArticle: func,
    category: object,
    article: object,
    hideContent: bool,
    changeArticleId: string,
    changeArticleTitle: string
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setCurrentArticle: setCurrentArticle
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        category: state.writingReducer.category,
        article: state.writingReducer.article,
        changeArticleId: state.writingReducer.changeArticleId,
        changeArticleTitle: state.writingReducer.changeArticleTitle
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Articles);
