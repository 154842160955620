import { message } from 'antd';
import SignOutEventEmitter from '../utils/signOutEventEmitter'
import {HOST} from "../utils/config";

const checkStatus = res => {
    // console.log(res);
    if(res.status == '401'){
        SignOutEventEmitter.emit('SIGN_OUT');
        // message.error('登录失效');
    }else if (200 >= res.status < 300) {
        return res;
    }
    if(res.status != 401){
        message.error(`网络请求失败,${res.status}`);
    }

    const error = new Error(res.statusText);
    error.response = res;
    throw error;
};

/**
 *  捕获成功登录过期状态码等
 * @param res
 * @returns {*}
 */
const judgeOkState = async res => {
    const cloneRes = await res.clone().json();
    //TODO:可以在这里管控全局请求
    if (cloneRes.status !== 'S00' && cloneRes.status != '401') {
        message.error(`${cloneRes.message}${cloneRes.status}`);
    }
    return res;
};

/**
 * 捕获失败
 * @param error
 */
const handleError = error => {
    if (error instanceof TypeError) {
        message.error(`网络请求失败啦！${error}`);
    }
    return {   //防止页面崩溃，因为每个接口都有判断res.code以及data
        code: -1,
        data: false,
    };
};

class http {
    /**
     *静态的fetch请求通用方法
     * @param url
     * @param options
     * @returns {Promise<unknown>}
     */
    static async staticFetch(url = '', options = {}) {

        const token = localStorage.getItem('token');
        let headers = {};
        if (token) {
            headers = {
                authorization: token,
            }
        }

        const defaultOptions = {
            /*允许携带cookies*/
            credentials: 'include',
            /*允许跨域**/
            mode: 'cors',
            headers: headers,
        };
        // eslint-disable-next-line no-constant-condition
        if (options.method === 'POST' || 'PUT') {
            defaultOptions.headers['Content-Type'] = 'application/json; charset=utf-8';
        }
        defaultOptions.headers['channel'] = 'community-web';
        const newOptions = { ...defaultOptions, ...options };
        // console.log('newOptions', newOptions);
        const baseUrl = HOST.api;
        // const baseUrl = 'https://www.shanganonline.com/mic-test';
        url = baseUrl + url;
        return fetch(url, newOptions)
            .then(checkStatus)
            .then(judgeOkState)
            .then(res => res.json())
            .catch(handleError);
    }

    /**
     *post请求方式
     * @param url
     * @returns {Promise<unknown>}
     */
    post(url, params = {}, option = {}) {
        const options = Object.assign({ method: 'POST' }, option);
        //一般我们常用场景用的是json，所以需要在headers加Content-Type类型
        options.body = JSON.stringify(params);

        //可以是上传键值对形式，也可以是文件，使用append创造键值对数据
        if (options.type === 'FormData' && options.body !== 'undefined') {
            let params = new FormData();
            for (let key of Object.keys(options.body)) {
                params.append(key, options.body[key]);
            }
            options.body = params;
        }
        return http.staticFetch(url, options); //类的静态方法只能通过类本身调用
    }

    /**
     * put方法
     * @param url
     * @returns {Promise<unknown>}
     */
    put(url, params = {}, option = {}) {
        const options = Object.assign({ method: 'PUT' }, option);
        options.body = JSON.stringify(params);
        return http.staticFetch(url, options); //类的静态方法只能通过类本身调用
    }

    /**
     * get请求方式
     * @param url
     * @param option
     */
    get(url, param = {}, option = {}) {
        const options = Object.assign({ method: 'GET' }, option);
        if(param && Object.keys(param).length > 0){
            let paramString = "";
            Object.keys(param).forEach(key => {
                let value = param[key] !== undefined ? param[key] : '';
                paramString += `&${key}=${value}`
            });
            url = `${url}?${paramString.substr(1)}`
        }
        return http.staticFetch(url, options);
    }
}

const httpClient = new http(); //new生成实例
export const { post, get, put } = httpClient;
export default httpClient;
