import WritingStory from "src/storyBook/writing/writing.story";
import ArticleDetailStory from "src/storyBook/articleDetail/articleDetail.story";
import NotificationsStory from "src/storyBook/notifications/notifications.story";
import HomeStory from "./storyBook/home/home.story";
import InterviewExpStory from "./storyBook/interviewExp/interviewExp.story";
import FollowingStory from "./storyBook/following/following.story";
import AuthorStory from "./storyBook/author/author.story";
import SettingStory from "./storyBook/setting/setting.story";
import FeedbackStory from "./storyBook/feedback/feedback.story";
import BasicLayout from "./layouts/BasicLayout";
import WorkExpStory from "./storyBook/workExp/workExp.story";
import AcademicStory from "./storyBook/academic/academic.story";
import SearchStory from "./storyBook/search/search.story";
import QuestionAnswerStory from "./storyBook/questionAnswer/questionAnswer.story";

const routes = [
  {
    path: "/writing",
    component: WritingStory,
    exact: true,
  },
  {
    path: "/",
    component: BasicLayout,
    routes: [
      {
        path: "/interview_experience",
        component: InterviewExpStory,
        exact: true,
      },
      {
        path: "/qa",
        component: QuestionAnswerStory,
        exact: true,
      },
      {
        path: "/work_experience",
        component: WorkExpStory,
        exact: true,
      },
      {
        path: "/academic",
        component: AcademicStory,
        exact: true,
      },
      {
        path: "/search",
        component: SearchStory,
        exact: true,
      },
      {
        path: "/following",
        component: FollowingStory,
        exact: true
      },
      {
        path: "/message/:notiType?",
        component: NotificationsStory,
        exact: false,
      },
      {
        path: "/detail/:articleId",
        component: ArticleDetailStory,
        exact: true,
      },
      {
        path: "/author/:authorId/liked_article",
        component: AuthorStory,
        exact: false,
      },
      {
        path: "/author/:authorId/collected_article",
        component: AuthorStory,
        exact: false,
      },
      {
        path: "/author/:authorId/following",
        component: AuthorStory,
        exact: false,
      },
      {
        path: "/author/:authorId/follower",
        component: AuthorStory,
        exact: false,
      },
      {
        path: "/author/:authorId",
        component: AuthorStory,
        exact: true,
      },
      {
        path: "/setting",
        component: SettingStory,
        exact: true,
      },
      {
        path: "/feedback",
        component: FeedbackStory,
        exact: true,
      },
      {
        path: "/",
        component: HomeStory,
        exact: true,
      },
    ],
  },
];
export default routes;
