import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import 'src/App.css';
import ArticleCard from "../../components/articleCard/articleCard";
import {netArticleByCategory} from "../../network/articleNetwork";
import {Button, Empty} from "antd";
import moduleCss from "./questionAnswer.story.module.css";
import TagFilter from "../../components/tagFilter/tagFilter";

function QuestionAnswerStory() {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNo, setPageNo] = useState(0);
    const [noMorePage, setNoMorePage] = useState(false);
    let tags = [];
    let refreshByTag = false;
    let didCancel = false;

    useEffect(()=>{
        requestArticleList();

        return ()=>{
            didCancel = true;
        }
    }, [pageNo]);

    function requestArticleList (){
        setLoading(true);
        const param = {
            pageNo: pageNo,
            pageSize: 20,
            tags: tags
        }
        netArticleByCategory("04", param).then((res) => {
            if(!didCancel){
                if(res.result){
                    if(refreshByTag){
                        setArticles(res.result)
                    }else{
                        setArticles(articles => [...articles, ...res.result]);
                    }
                    if(res.result.length < 20){
                        setNoMorePage(true);
                    }
                }else{
                    setArticles([]);
                    setNoMorePage(true);
                }
            }
        }).finally(()=>{
            if(!didCancel){
                setLoading(false);
            }
            refreshByTag = false;
        })
    }

    return (
        <div className={moduleCss.container}>
            <TagFilter
                onChange={(itemList)=>{
                    tags = itemList;
                    setPageNo(0);
                    refreshByTag = true;
                    requestArticleList();
                }}
            ></TagFilter>
            <ArticleCard
                article={articles}
            />
            {
                noMorePage && articles == 0
                    ? <Empty description={"没有更多内容了～"}/>
                    : <Button
                        className={moduleCss.loadingMoreButton}
                        type={"link"}
                        disabled={noMorePage}
                        loading={loading}
                        onClick={()=>{
                            setPageNo(pageNo => pageNo + 1);
                        }}>{noMorePage ? '没有更多内容了～' : '加载更多'}</Button>
            }
        </div>
    );
}

QuestionAnswerStory.defaultProps = {
}

QuestionAnswerStory.propTypes = {
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(QuestionAnswerStory);
