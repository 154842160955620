import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import FollowingUser from "../followingUser/followingUser";
import {bindActionCreators} from "redux";
import {selectFollowingAction} from "../../../../redux/actions/selectFollowingAction";
import {func, shape} from "prop-types";
import {netMyFollowingList} from "../../../../network/authorNetwork";
import {Skeleton} from "antd";

function FollowingList(
    {
        selectedFollowing,
        onSelectFollowing
    }
){
    const [loading, setLoading] = useState(false);
    const [followingList, setFollowingList] = useState([]);
    useEffect(()=>{
        let didCancel = false;
        setLoading(true);
        netMyFollowingList().then((res)=>{
            if(res.status == 'S00' &&  res.result && !didCancel){
                setFollowingList(res.result);
                onSelectFollowing(res.result[0]);
            }
        }).finally(()=>{
            if(!didCancel){
                setLoading(false);
            }
        })
        return ()=>{
            didCancel = true;
        }
    },[onSelectFollowing])

    useEffect(()=>{
        if(!selectedFollowing){
            onSelectFollowing(followingList[0])
        }
    },[selectedFollowing, onSelectFollowing, followingList])

    return (
        <div>
            {
                loading ? <Skeleton active={true}/> :
                followingList.map((item)=>(
                    <FollowingUser key={item.id}
                                   following={item}
                    />
                ))
            }
        </div>
    );
}

FollowingList.propTypes = {
    selectedFollowing: shape({}),
    onSelectFollowing: func
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onSelectFollowing: selectFollowingAction,
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        selectedFollowing: state.selectFollowingReducer.following
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FollowingList);
