import React from "react";
import {connect} from "react-redux";
import moduleCss from "./authorTimelineCard.module.css";
import {HeartFilled, MessageFilled} from "@ant-design/icons";
import {Avatar} from "antd";

function AuthorTimelineCard() {
    return (
        <div className={moduleCss.container}>
            <div className={moduleCss.authorInfo}>
                <Avatar size={24} src="https://joeschmoe.io/api/v1/random"></Avatar>
                <div className={moduleCss.authorInfoText}>作者名称</div>
                <div className={moduleCss.authorInfoText}>发表了文章</div>
                <div className={moduleCss.authorInfoText}>2021.09.19 13:34:65</div>
            </div>
            <div className={moduleCss.title}>
                标题标题标题标题标题标题
            </div>
            <div className={moduleCss.content}>
                内容内容内容内容内容内容内容内容内容内容内容内容
            </div>
            <div className={moduleCss.articleInfo}>
                <div className={moduleCss.comment}>
                    <MessageFilled size={11} style={{color:"#979797"}} />
                    <span className={moduleCss.commentLikeNumber}>27</span>
                </div>
                <div className={moduleCss.like}>
                    <HeartFilled style={{width: "11px", color:"#979797"}}/>
                    <span className={moduleCss.commentLikeNumber}>126</span>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AuthorTimelineCard);
