import {get, post} from "./network";

const communityServicePrefix = "/community";

export async function netGetArticleCategory() {
    return get(communityServicePrefix + '/writer/v1/category');
}

export async function netGetArticleDraft(param) {
    return get(communityServicePrefix + '/writer/v1/author_article_draft', param);
}

export async function netSaveArticleDraft(param) {
    return post(communityServicePrefix + '/writer/v1/save', param);
}

export async function netPublishArticle(param) {
    return post(communityServicePrefix + '/writer/v1/publish', param);
}

export async function netTakeDownArticle(param) {
    return post(communityServicePrefix + '/writer/v1/take_down', param);
}

export async function netDeleteArticle(param) {
    return post(communityServicePrefix + '/writer/v1/delete', param);
}

export async function netAuditResult(param) {
    return get(communityServicePrefix + '/writer/v1/audit_result', param);
}
