import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import moduleCss from "./author.story.module.css"
import AuthorBriefInfo from "./components/authorBriefInfo/authorBriefInfo";
import AuthorIntro from "./components/authorIntro/authorIntro";
import AuthorArticle from "./components/authorArticle/authorArticle";
import {useLocation} from "react-router-dom/cjs/react-router-dom";
import AuthorLikedArticle from "./components/authorLikedArticle/authorLikedArticle";
import AuthorFollower from "./components/authorFollower/authorFollower";
import {shape} from "prop-types";
import {bindActionCreators} from "redux";
import {netAuthorInfo} from "../../network/authorNetwork";
import {Skeleton} from "antd";

function AuthorStory({match}){
    const authorId = match.params.authorId;
    let location = useLocation();
    const routeList = location.pathname.split("/");
    let currentRoute = "";
    if(routeList.length == 4) {
        currentRoute = routeList[routeList.length - 1];
    }
    const [authorRoute, setAuthorRoute] = useState(currentRoute);

    const [author, setAuthor] = useState({});

    useEffect(()=>{
        let didCancel = false;
        netAuthorInfo(authorId).then(res => {
            if(res.status == 'S00' && !didCancel){
                setAuthor(res.result);
            }
        }).finally(()=>{

        })
        return ()=>{
            didCancel = true;
        }
    }, [authorId]);

    useEffect(()=>{
        let location = window.location;
        const routeList = location.pathname.split("/");
        let currentRoute = "";
        if(routeList.length == 4) {
            currentRoute = routeList[routeList.length - 1];
        }
        setAuthorRoute(currentRoute);
    }, [])

    return (
        author ?
        <div className={moduleCss.container}>
            <div className={moduleCss.leftContainer}>
                <AuthorBriefInfo author={author}></AuthorBriefInfo>
                <div className={moduleCss.authArticle}>
                    {
                        authorRoute.length == 0 ?
                            <AuthorArticle author={author}></AuthorArticle>
                            :
                            authorRoute == 'liked_article' || authorRoute == 'collected_article' ?
                                <AuthorLikedArticle author={author} currentTab={authorRoute}></AuthorLikedArticle>
                                :
                                authorRoute == 'following' || authorRoute == 'follower' ?
                                    <AuthorFollower author={author} currentTab={authorRoute}></AuthorFollower>
                                    :
                                    <></>
                    }

                </div>
            </div>
            <div className={moduleCss.authorInfo}>
                <AuthorIntro author={author}></AuthorIntro>
            </div>
        </div> : <Skeleton active></Skeleton>
    )
}

AuthorStory.defaultProps = {}

AuthorStory.propTypes = {
    match: shape({}),
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

function mapStateToProps(state) {
    return {
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AuthorStory);
