import React, {useState} from "react";
import {connect} from "react-redux";
import moduleCss from "./feedback.story.module.css";
import TextArea from "antd/es/input/TextArea";
import {Button} from "antd";
import {netFeedback} from "../../network/feedbackNetwork";
import { message } from 'antd';

function FeedbackStory(){
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState();
    return (
        <div className={moduleCss.container}>
            <TextArea className={moduleCss.textArea} rows={15}
                      placeholder={"请对我们吐槽或者提供任何您觉得不合理的使用体验，谢谢～"}
                      onChange={(e) => {
                          setContent(e.target.value);
                      }}
            >

            </TextArea>
            <Button className={moduleCss.submitBtn}
                    type={"primary"}
                    loading={loading}
                    onClick={()=>{
                        const param = {
                            content: content
                        }
                        setLoading(true);
                        netFeedback(param).then(res => {
                            if(res.status == 'S00'){
                                message.success('感谢您的反馈!');
                            }
                        }).finally(()=>{
                            setLoading(false);
                        })
                    }}
            >提交</Button>
        </div>
    )
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FeedbackStory);
