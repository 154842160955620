import React, {useEffect, useState} from "react";
import {Button, Empty, Skeleton} from "antd";
import styles from "./index.module.css";
import {netMessageByType} from "../../../../network/messageNetwork";
import moduleCss from "../../../interviewExp/interviewExp.story.module.css";
import moment from "moment";
import AvatarImg from "../../../../components/avatar/avatarImg";
import {connect} from "react-redux";

const Follows = () => {
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [noMorePage, setNoMorePage] = useState(false);
    const [emptyPage, setEmptyPage] = useState(false);

    const [page, setPage] = useState(0);

    useEffect(() => {
        let didCancel = false;
        const requestData = () => {
            const param = {
                pageNo: page,
                pageSize: 20,
                type: "03"
            }
            setLoading(true);
            netMessageByType(param).then(res => {
                if (res && res.status == 'S00' && !didCancel) {
                    if(res.result){
                        setDataList(data => [...data, ...res.result]);
                        if (!res.result || res.result.length < 20) {
                            setNoMorePage(true);
                        }
                    }else{
                        if(dataList.length == 0){
                            setEmptyPage(true);
                        }else{
                            setNoMorePage(true);
                        }
                    }
                }
            }).finally(() => {
                if (!didCancel) {
                    setLoading(false);
                }
            });
        }
        requestData();
        return () => {
            didCancel = true;
        }
    }, [page]);
    return (
        <div className={styles.recommends}>
            <div className={styles.recommendsTitle}>关注</div>
            {dataList.length == 0 && loading ? <Skeleton active={loading}/> : dataList.map((item) => (
                <div key={item.id} className={styles.block}>
                    <div className={styles.blockHeader}>
                        <AvatarImg size={48} src={item.fromUserAvatar}/>
                    </div>
                    <div
                        className={styles.blockTime}>{moment.unix(item.createdTime / 1000).format('YYYY.MM.DD HH:mm:ss')}</div>
                    <div className={styles.blockName}>{item.fromUserNickname}</div>
                    <div className={styles.blockArticle}>关注了你</div>
                </div>
            ))}
            {
                dataList.length > 0 ? <Button
                    className={moduleCss.loadingMoreButton}
                    type={"link"}
                    disabled={noMorePage}
                    loading={loading}
                    onClick={() => {
                        setPage(pageNo => pageNo + 1);
                    }}>{noMorePage ? '没有更多内容了～' : '加载更多'}</Button> : null
            }
            {
                emptyPage ? <Empty description={false}/> : null
            }
        </div>
    );
};

Follows.propTypes = {

};
const mapDispatchToProps = () => {
    return {};
};

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Follows);
