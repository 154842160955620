import {get} from "./network";

const communityServicePrefix = "/community";

/**
 * 01. 评论
 02. 喜欢
 03. 关注
 04. 收藏
 * @param param
 * @returns {Promise<*>}
 */
export async function netMessageByType(param) {
    return get(communityServicePrefix + '/message/v1/message_by_type', param);
}
