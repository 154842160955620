import React, {useEffect, useState} from "react";
import moduleCss from "./comments.module.css";
import {Form, Input, Button, Comment} from "antd";
import {shape} from "prop-types";
import {message} from 'antd';
import {netComment, netCommentList} from "../../../../network/articleNetwork";
import AvatarImg from "../../../../components/avatar/avatarImg";
import SignInEventEmitter from "../../../../utils/signInEventEmitter";
import deviceUtil from "../../../../utils/deviceUtil";
import {Link} from "react-router-dom";

const Comments = ({article} = {}) => {
    const [focused, setFocused] = useState(false);
    const [form] = Form.useForm();
    const [replyForm] = Form.useForm();
    const [commentLoading, setCommentLoading] = useState(false);
    const [replyLoading, setReplyLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageNo, setPageNo] = useState(0);
    const [noMorePage, setNoMorePage] = useState(false);
    const [commentList, setCommentList] = useState([]);
    const [reply, setReply] = useState(null);
    const [parent, setParent] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(deviceUtil.isMobile);
    }, []);

    useEffect(() => {
        const param = {
            pageNo: pageNo,
            pageSize: 20,
            articleId: article.id
        }
        setLoading(true);
        netCommentList(param).then((res) => {
            if(res.status == 'S00'){
                if (res.result) {
                    setCommentList(comments => [...comments, ...res.result]);
                    if (res.result.length < 20) {
                        setNoMorePage(true);
                    }
                }else{
                    setNoMorePage(true);
                }
            }

        }).finally(() => {
            setLoading(false);
        })
    }, [pageNo, article.id])

    const onSubmit = () => {
        const values = form.getFieldsValue();
        if (!values.comment) {
            message.info("请输入评论内容")
            return;
        }
        const param = {
            articleId: article.id,
            content: values.comment
        }
        setCommentLoading(true);
        netComment(param).then(res => {
            if (res.status == 'S00') {
                commentList.unshift(res.result);
            }
        }).finally(() => {
            form.resetFields();
            setFocused(false);
            setCommentLoading(false);
        })
    }

    const onReplay = (index) => {
        const values = replyForm.getFieldsValue();
        if (!values.comment) {
            message.info("请输入回复内容")
            return;
        }
        const param = {
            articleId: article.id,
            parentId: parent.id,
            replyToId: reply.id,
            content: values.comment
        }
        setReplyLoading(true);
        netComment(param).then(res => {
            if (res.status == 'S00') {
                let tempList = commentList;
                if(!tempList[index].children) {
                    tempList[index].children = [];
                }
                tempList[index].children.push(res.result);
                setCommentList(tempList);
                setReply(null);
                setParent(null);
            }
        }).finally(() => {
            form.resetFields();
            setFocused(false);
            setReplyLoading(false);
        })
    }

    return (
        <div className={moduleCss.comments}>
            <Form
                form={form}
                name="basic"
                labelCol={{span: 0}}
                wrapperCol={{span: 24}}
                initialValues={{remember: true}}
                onFinish={null}
                onFinishFailed={null}
                autoComplete="off"
            >
                <Form.Item
                    label=""
                    name="comment"
                    rules={[{required: true, message: "请先输入内容"}]}
                >
                    <Input.TextArea
                        className="comment-textarea"
                        rows={4}
                        placeholder="写下你的评论.."
                        name="comment"
                        onFocus={() => {
                            setFocused(true)
                        }}
                    />
                </Form.Item>
                {
                    focused ? <Form.Item
                        wrapperCol={{offset: isMobile ? 0 : 8, span: 16}}
                        style={{textAlign: "right"}}
                    >
                        <Button
                            type="primary"
                            shape="round"
                            size="small"
                            className="follow-btn"
                            loading={commentLoading}
                            onClick={() => {
                                if(!SignInEventEmitter.judgeLogin()){
                                    return;
                                }
                                onSubmit()
                            }}
                            style={{marginRight: "10px"}}
                        >
                            发布
                        </Button>
                        <Button
                            type="default"
                            shape="round"
                            size="small"
                            className="follow-btn"
                            onClick={() => {
                                setFocused(false);
                            }}
                        >
                            取消
                        </Button>
                    </Form.Item> : null
                }
            </Form>
            <div className={moduleCss.commentsTitle}>精彩评论</div>
            {
                commentList.map((comment, index) => (
                    <Comment
                        key={comment.id}
                        actions={[<span key="comment-nested-reply-to"
                                        onClick={() => {
                                            if(!SignInEventEmitter.judgeLogin()){
                                                return;
                                            }
                                            setReply(comment);
                                            setParent(comment);
                                        }}
                        >回复</span>]}
                        author={<Link target = "_blank" to={`/author/${comment.userId}`}>{comment.userNickname}</Link>}
                        avatar={
                            <Link target = "_blank" to={`/author/${comment.userId}`}>
                                <AvatarImg src={comment.userAvatar} alt={comment.userNickname}/>
                            </Link>
                        }
                        content={<p>{comment.content}</p>}
                    >
                        {comment.children ?
                            comment.children.map((child) => (
                                <Comment
                                    key={child.id}
                                    actions={[<span key="comment-nested-reply-to"
                                                    onClick={() => {
                                                        if(!SignInEventEmitter.judgeLogin()){
                                                            return;
                                                        }
                                                        setReply(child);
                                                        setParent(comment);
                                                    }}
                                    >回复</span>]}
                                    author={<a>{child.userNickname}</a>}
                                    avatar={
                                        <AvatarImg src={child.userAvatar} alt={child.userNickname}/>
                                    }
                                    content={<p>{<a href='http://localhost:3000/author/123' target="_blank" rel="noopener noreferrer">@{child.replyToUserNickname}</a>} {child.content}</p>}
                                ></Comment>
                            ))
                            :
                            null
                        }
                        {
                            reply && parent && parent.id == comment.id ? <Form
                                form={replyForm}
                                name="basic"
                                labelCol={{span: 0}}
                                wrapperCol={{span: 24}}
                                initialValues={{remember: true}}
                                onFinish={null}
                                onFinishFailed={null}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label=""
                                    name="comment"
                                    rules={[{required: true, message: "请先输入内容"}]}
                                >
                                    <Input.TextArea
                                        className={moduleCss.commentTextarea}
                                        rows={4}
                                        placeholder={'@' + reply.userNickname}
                                        name="comment"
                                    />
                                </Form.Item>
                                <Form.Item
                                    wrapperCol={{offset: isMobile ? 0 : 8, span: 16}}
                                    style={{textAlign: "right"}}
                                >
                                    <Button
                                        type="primary"
                                        shape="round"
                                        size="small"
                                        className="follow-btn"
                                        loading={replyLoading}
                                        onClick={() => {
                                            onReplay(index)
                                        }}
                                        style={{marginRight: "10px"}}
                                    >
                                        发布
                                    </Button>
                                    <Button
                                        type="default"
                                        shape="round"
                                        size="small"
                                        className="follow-btn"
                                        onClick={() => {
                                            setReply(null);
                                            setParent(null);
                                            replyForm.resetFields();
                                        }}
                                    >
                                        取消
                                    </Button>
                                </Form.Item>
                            </Form> : null
                        }
                    </Comment>
                ))
            }
            <Button
                className={moduleCss.loadingMoreButton}
                type={"link"}
                loading={loading}
                disabled={noMorePage}
                onClick={() => {
                    setPageNo(pageNo => pageNo + 1)
                }}>{noMorePage ? '没有更多评论了' : '加载更多'}</Button>
        </div>
    );
};

Comments.propTypes = {
    article: shape({}),
};

export default Comments;
