import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {RightOutlined} from "@ant-design/icons";
import moduleCss from "./authorBriefInfo.module.css"
import {Link} from "react-router-dom";
import {shape} from "prop-types";
import AvatarImg from "../../../../components/avatar/avatarImg";

function AuthorBriefInfo({author}){

    return (
        author ?
        <div className={moduleCss.container}>
            <AvatarImg size={80}
                    src={author.avatar}></AvatarImg>
            <div className={moduleCss.right}>
                <div className={moduleCss.authorName}>{author.nickname}</div>
                <div className={moduleCss.rightBottom}>
                    <Link to={`/author/${author.id}/following`}>
                        <div className={moduleCss.infoItem}>
                            <div className={moduleCss.infoItemValue}>{author.followCount}</div>
                            <div className={moduleCss.infoItemLabel}>关注 <RightOutlined /></div>
                        </div>
                    </Link>
                    <Link to={`/author/${author.id}/follower`}>
                        <div className={moduleCss.infoItem}>
                            <div className={moduleCss.infoItemValue}>{author.fansCount}</div>
                            <div className={moduleCss.infoItemLabel}>粉丝 <RightOutlined /></div>
                        </div>
                    </Link>
                    <Link to={`/author/${author.id}`}>
                        <div className={moduleCss.infoItem}>
                            <div className={moduleCss.infoItemValue}>{author.followCount}</div>
                            <div className={moduleCss.infoItemLabel}>文章 <RightOutlined /></div>
                        </div>
                    </Link>
                </div>
            </div>
        </div> : <></>
    )
}

AuthorBriefInfo.defaultProps = {}

AuthorBriefInfo.propTypes = {
    author: shape({}),
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AuthorBriefInfo);
