import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Divider, Select, Space, Tag, Typography} from 'antd';
import {any, array, func, string} from "prop-types";
import {netGetDictionaryList} from "../../network/commonNetwork";
import Input from "antd/es/input/Input";
import {PlusOutlined} from "@ant-design/icons";

function TagSelector({
                         tags,
                         onChange,
    articleId
                     }) {
    const [options, setOptions] = useState([]);
    const [newItem, setNewItem] = useState('');

    const onNewItemChange = (event) => {
        setNewItem(event.target.value);
    };

    const addItem = (e) => {
        e.preventDefault();
        if (newItem) {
            setOptions([...options, {value: newItem}]);
            setNewItem('');
        }
    };

    useEffect(() => {
        let didCancel = false;

        function requestTagList() {
            const param = {
                id: "article-tag",
            };
            netGetDictionaryList(param).then((res) => {
                if (res.status == "S00" && res.result && !didCancel) {
                    console.log(res.result);
                    setOptions(res.result.map(item => {
                        return {
                            value: item.value
                        }
                    }));
                }
            });
        }

        requestTagList();

        return () => {
            didCancel = true;
        };
    }, [setOptions]);

    const tagRender = ({label, value, closable, onClose}) => {

        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };

        return (
            <Tag
                color={'orange'}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{
                    marginRight: 3,
                }}
            >
                {label}
            </Tag>
        );
    };

    return (
        <Select
            mode="multiple"
            showArrow
            tagRender={tagRender}
            defaultValue={tags}
            key={articleId}
            style={{
                width: '80%',
            }}
            onChange={(items) => {
                onChange(items)
            }}
            options={options}
            // dropdownRender={(menu) => (
            //     <>
            //         {menu}
            //         <Divider
            //             style={{
            //                 margin: '8px 0',
            //             }}
            //         />
            //         <Space
            //             align="center"
            //             style={{
            //                 padding: '0 8px 4px',
            //             }}
            //         >
            //             <Input placeholder="自定义标签" showCount maxLength={20} value={newItem} onChange={onNewItemChange}/>
            //             <Typography.Link
            //                 onClick={addItem}
            //                 style={{
            //                     whiteSpace: 'nowrap',
            //                 }}
            //             >
            //                 <PlusOutlined/> 添加自定义标签
            //             </Typography.Link>
            //         </Space>
            //     </>
            // )}
        />

    )
}

TagSelector.defaultProps = {};

TagSelector.propTypes = {
    tags: array,
    onChange: func,
    articleId: any
};

const mapDispatchToProps = () => {
    return {};
};

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(TagSelector);
