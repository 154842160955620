import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Avatar, Button, Input, message, Upload} from "antd";
import moduleCss from "./settingBasic.module.css"
import {netBasicInfo, netGetVerifyCode, netResetPassword, netUpdateInfo} from "../../../../network/userNetwork";
import AvatarImg from "../../../../components/avatar/avatarImg";
import {rsaEncrypt} from "../../../../utils/rsaEncrypt";
import {netFileToken} from "../../../../network/fileNetwork";
import {HOST} from "../../../../utils/config";
import OSS from 'ali-oss';
import {bindActionCreators} from "redux";
import {updateUserAction} from "../../../../redux/actions/userAction";
import {func, shape} from "prop-types";
import deviceUtil from "../../../../utils/deviceUtil";

function SettingBasic({
    updateUserAction,
    user
                      }){

    const [isEditPassword, setIsEditPassword] = useState(false);
    const [basicInfo, setBasicInfo] = useState(null);
    const [newAvatar, setNewAvatar] = useState(null);
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [codeLoading, setCodeLoading] = useState(false);
    const [countSeconds, setCountSeconds] = useState(60);
    const [counting, setCounting] = useState(false);
    const [verifyCode, setVerifyCode] = useState('');
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [sts, setSts] = useState();
    let seconds = 60;

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(deviceUtil.isMobile);
    }, []);

    useEffect(()=>{
        let didCancel = false;
        const requestBasicInfo = () => {
            netBasicInfo().then(res => {
                if(res.status == 'S00' && !didCancel){
                    setBasicInfo(res.result);
                }
            }).finally(()=>{

            })
        }
        requestBasicInfo();

        return ()=>{
            didCancel = true;
        }
    }, [])

    const onCountDown = () => {
        if(seconds == 0){
            setCounting(false);
            setCountSeconds(60);
            seconds = 60
        }else{
            seconds = seconds - 1;
            setCountSeconds(seconds);
            setTimeout(()=>{
                onCountDown();
            }, 1000);
        }
    }

    const onGetEmailCode = () => {
        setCodeLoading(true);
        const param = {
            type: 'reset'
        }
        netGetVerifyCode(basicInfo.email, param).then(res => {
            if(res.status == 'S00'){
                setCounting(true);
                onCountDown();
                message.success('验证码已发送，请注意查收')
            }
        }).finally(()=>{
            setCodeLoading(false);
        })
    }

    const onSaveInfo = () => {
        if(isEditPassword){
            if(verifyCode.length == 0){
                message.info("请输入验证码");
                return;
            }
            if(password !== confirmPassword){
                message.info("两次密码不一致");
                return;
            }
            setLoading(true);
            const param = {
                email: basicInfo.email,
                code: verifyCode,
                password: rsaEncrypt(password)
            }
            netResetPassword(param).then((res) => {
                if(res.status == 'S00'){
                    message.success('密码修改成功');
                }
            }).finally(()=>{
                setLoading(false);
            })
        }else{
            const param = {
                nickName: basicInfo.nickName,
                email: basicInfo.email
            }
            if(newAvatar){
                param.avatar = newAvatar
            }
            setLoading(true);
            netUpdateInfo(param).then(res => {
                if(res.status == 'S00'){
                    message.success('信息保存成功');
                    let newObj = JSON.parse(JSON.stringify(user));
                    newObj.nickName = basicInfo.nickName;
                    newObj.email = basicInfo.email;
                    if(newAvatar){
                        newObj.avatar = newAvatar;
                    }
                    updateUserAction(newObj);
                }
            }).finally(()=>{
                setLoading(false);
            })
        }
    }

    const getFileToken = () => {
        netFileToken().then(res => {
            if(res.status == 'S00'){
                setSts(res.result);
            }
        })
    }

    const onChangeAvatar = async (e) => {
        if(e && e.event && e.event.percent == 100){
            console.log(e);
            const store = new OSS({
                accessKeyId: sts.AccessKeyId,
                accessKeySecret: sts.AccessKeySecret,
                bucket: sts.bucketName,
                endpoint: HOST.ossEndpoint,
                stsToken: sts.SecurityToken,
                refreshSTSToken: async () => {
                    netFileToken().then(res => {
                        console.log(res);
                        if (res.status == 'S00') {
                            return {
                                accessKeyId: res.result.AccessKeyId,
                                accessKeySecret: res.result.AccessKeySecret,
                                stsToken: res.result.SecurityToken
                            }
                        }
                    })
                },
                refreshSTSTokenInterval: 300000
            });

            let suffix = e.file.name.split('.').reverse()[0];

            setUploading(true);
            const result = await store.multipartUpload(`${basicInfo.id}/avatar/${new Date().getTime()}.${suffix}`, e.file.originFileObj);

            if(result && result.res && result.res.status == 200){
                setNewAvatar(result.name);
            }
            setUploading(false);
        }

    }

    return(
        basicInfo ?
        <>
            <div className={moduleCss.settingItem} style={{borderBottom: 'none', paddingTop: 0}}>
                <div className={moduleCss.itemLeft} >
                    <AvatarImg src={newAvatar ? newAvatar : basicInfo.avatar}
                               size={isMobile ?  60 : 100}></AvatarImg>
                </div>
                <Upload onChange={onChangeAvatar}
                        showUploadList={false}>
                    <Button onClick={()=>{getFileToken()}} loading={uploading}>更改头像</Button>
                </Upload>
            </div>
            <div className={moduleCss.settingItem}>
                <div className={`${moduleCss.itemLeft} ${moduleCss.itemTitle}`}>昵称</div>
                <Input className={moduleCss.input}
                       defaultValue={basicInfo.nickName}
                       disabled={isEditPassword}
                       onChange={(e)=>{
                           let data = basicInfo;
                           data.nickName = e.target.value;
                           setBasicInfo(data)
                       }}/>
            </div>
            <div className={moduleCss.settingItem}>
                <div className={`${moduleCss.itemLeft} ${moduleCss.itemTitle}`}>邮箱</div>
                <Input className={moduleCss.input}
                       defaultValue={basicInfo.email}
                       disabled={isEditPassword}
                       onChange={(e)=>{
                            let data = basicInfo;
                            data.email = e.target.value;
                            setBasicInfo(data)
                        }}/>
            </div>
            <div className={moduleCss.settingItem}>
                <div className={`${moduleCss.itemLeft} ${moduleCss.itemTitle}`}>修改密码</div>
                <Button onClick={()=>{ setIsEditPassword(!isEditPassword)}}>{isEditPassword ? '取消' : '修改'}</Button>
            </div>
            {
                isEditPassword ?
                    <div style={{paddingLeft: '20px'}}>
                        <div className={moduleCss.settingItem}>
                            <div className={`${moduleCss.itemLeft} ${moduleCss.itemTitle}`}>验证码</div>
                            <Input className={moduleCss.codeInput}
                                   onChange={e => {
                                       setVerifyCode(e.target.value)
                                   }}/>
                            <Button className={moduleCss.codeButton}
                                    disabled={counting}
                                    loading={codeLoading}
                                    onClick={()=>{
                                        onGetEmailCode();
                                    }}
                            >{counting ? `${countSeconds}秒后重新获取` : `获取验证码`}</Button>
                        </div>
                        <div className={moduleCss.settingItem}>
                            <div className={`${moduleCss.itemLeft} ${moduleCss.itemTitle}`}>新密码</div>
                            <Input.Password className={moduleCss.input} onChange={e => {
                                setPassword(e.target.value);
                            }}/>
                        </div>
                        <div className={moduleCss.settingItem}>
                            <div className={`${moduleCss.itemLeft} ${moduleCss.itemTitle}`}>确认密码</div>
                            <Input.Password className={moduleCss.input} onChange={e => {
                                setConfirmPassword(e.target.value);
                            }}/>
                        </div>
                    </div>:
                    <></>
            }
            <Button className={moduleCss.saveButton}
                    type={"primary"}
                    loading={loading}
                    onClick={()=>{
                        onSaveInfo();
                    }}>保存</Button>
        </> : <></>
    )
}

SettingBasic.propTypes = {
    updateUserAction: func,
    user: shape({})
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateUserAction: updateUserAction,
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.userReducer.user
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingBasic);

