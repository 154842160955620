import React from "react";
import {connect} from "react-redux";
import styles from "./notifications.story.module.css";
import {Layout, Row, Col} from "antd";
import Comments from "./components/Comments";
import Likes from "./components/Likes";
import Follows from "./components/Follows";
import Collects from "./components/Collects";
import NotiTypes from "./components/NotiTypes";
import {
    CommentOutlined,
    HeartOutlined,
    StarOutlined,
    EyeOutlined,
} from "@ant-design/icons";
import {object} from "prop-types";

const {Content} = Layout;

const NOTI_TYPES = {
    comments: {
        key: "comments",
        text: "收到的评论",
        icon: <CommentOutlined/>,
        // eslint-disable-next-line react/display-name
        component: () => <Comments/>,
    },
    likes: {
        key: "likes",
        text: "收到的喜欢",
        icon: <HeartOutlined/>,
        // eslint-disable-next-line react/display-name
        component: () => <Likes/>,
    },
    follows: {
        key: "follows",
        text: "关注",
        icon: <EyeOutlined/>,
        // eslint-disable-next-line react/display-name
        component: () => <Follows/>,
    },
    collects: {
        key: "collects",
        text: "收藏",
        icon: <StarOutlined/>,
        // eslint-disable-next-line react/display-name
        component: () => <Collects/>,
    },
};

function NotificationsStory({match}) {
    const current =
        NOTI_TYPES[match?.params?.notiType]?.key || NOTI_TYPES.comments.key;

    return (
        <Layout
            style={{
                height: "calc(100vh - 56px)",
                overflow: "auto",
            }}
        >
            <Content className={styles.detailMain}>
                <Content className={styles.detailLayout}>
                    <Row gutter={10} style={{paddingBottom: "10px"}}>
                        <Col span={7}>
                            <NotiTypes current={current} types={NOTI_TYPES}/>
                        </Col>
                        <Col span={17}>
                            {NOTI_TYPES[current].component()}
                        </Col>
                    </Row>
                </Content>
            </Content>
        </Layout>
    );
}

NotificationsStory.defaultProps = {};

NotificationsStory.propTypes = {
    match: object,
};

const mapDispatchToProps = () => {
    return {};
};

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsStory);
