import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Input, message } from "antd";
import moduleCss from "./signIn.module.css";
import { signInAction } from "../../../../redux/actions/userAction";
import { func } from "prop-types";
import { netSignIn } from "../../../../network/userNetwork";
import { rsaEncrypt } from "../../../../utils/rsaEncrypt";

function SignIn({ signInAction }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const onKeyDown = (e) => {
    switch (e.keyCode) {
      case 13:
        onLogin();
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown); // 添加全局事件
    return () => {
      window.removeEventListener("keydown", onKeyDown); // 销毁
    };
  }, []);

  const onLogin = () => {
    if (!email) {
      message.info("请输入邮箱");
      return;
    }
    if (!password) {
      message.info("请输入密码");
      return;
    }
    const params = {
      email: email,
      password: rsaEncrypt(password),
    };
    setLoading(true);
    netSignIn(params)
      .then((res) => {
        const token = res.result.token;
        localStorage.setItem("token", token);
        let user = {
          id: res.result.id,
          avatar: res.result.avatar,
          career: res.result.career,
          email: res.result.email,
          nickName: res.result.nickName,
          username: res.result.username,
        };
        localStorage.setItem("user", JSON.stringify(user));
        signInAction(user);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className={moduleCss.container}>
      <div className={moduleCss.title}>登录</div>
      <Input
        className={`${moduleCss.input} ${moduleCss.username}`}
        placeholder="邮箱"
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <Input.Password
        className={`${moduleCss.input} ${moduleCss.password}`}
        placeholder="密码"
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />
      <Button
        className={moduleCss.signBtn}
        onClick={() => {
          onLogin();
        }}
        loading={loading}
        type={"primary"}
      >
        {loading ? "登录中..." : "登录"}
      </Button>
    </div>
  );
}

SignIn.propTypes = {
  signInAction: func,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signInAction: signInAction,
    },
    dispatch
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
