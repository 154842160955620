import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import moduleCss from "./articleDetail.story.module.css";
import { Layout, Row, Col } from "antd";
import Article from "./components/Article";
import Comments from "./components/comments/comment";
import ArticleFooter from "./components/articleFooter/articleFooter";
import AuthorBlock from "./components/authorBlock/authorBlock";
// import Recommended from "./components/Recommended";
import {netArticleDetail, netReadArticle} from "../../network/articleNetwork";
import {func, shape} from "prop-types";
import {bindActionCreators} from "redux";
import {followAction} from "../../redux/actions/authorAction";
import deviceUtil from "../../utils/deviceUtil";

const { Content, Footer } = Layout;

function ArticleDetailStory({match, followAction}) {
  const articleId = match.params.articleId;
  const [article, setArticle] = useState();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(deviceUtil.isMobile);
  }, []);

  useEffect(()=>{
    let didCancel = false;
    function requestDetail(){
      netArticleDetail({articleId: articleId}).then(res => {
        if(res.status == 'S00' && !didCancel){
          setArticle(res.result);
          followAction(res.result.author.followed);
        }
      })
    }

    function read(){
      netReadArticle({articleId: articleId}).finally();
    }

    requestDetail();
    read();
    return ()=>{
      didCancel = true;
    }
  }, [articleId, followAction]);

  return (
      article ?
    <Layout
      style={{
        height: "calc(100vh - 112px)",
        overflow: "auto",
      }}
    >
      <Content className={moduleCss.detailMain}>
        <Content className={moduleCss.detailLayout}>
          {
            isMobile ? <Col>
              <Article article={article}></Article>
              <Comments article={article}></Comments>
            </Col> : <Row gutter={10} style={{ paddingBottom: "10px" }}>
              <Col span={17}>
                <Article article={article}></Article>
                <Comments article={article}></Comments>
              </Col>
              <Col span={7}>
                <AuthorBlock author={article.author}/>
                {/*<Recommended />*/}
              </Col>
            </Row>
          }
        </Content>
      </Content>
      <Footer className={moduleCss.detailFooter}>
        <ArticleFooter article={article} />
      </Footer>
    </Layout> : <></>
  );
}

ArticleDetailStory.defaultProps = {

};

ArticleDetailStory.propTypes = {
  match: shape({}),
  followAction: func
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    followAction: followAction
  }, dispatch);
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleDetailStory);
