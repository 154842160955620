export const setCurrentCategory = (category) => ({
    type: 'SET_CURRENT_CATEGORY',
    category: category
})

export const setCurrentArticle = (article) => ({
    type: 'SET_CURRENT_ARTICLE',
    article: article
})

export const changeArticleTitle = (articleId, articleTitle) => ({
    type: 'CHANGE_ARTICLE_TITLE',
    articleId: articleId,
    articleTitle: articleTitle
})
