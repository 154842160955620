import React from 'react';
import { connect } from 'react-redux';
import {
    array,
    number,
    string
} from 'prop-types';
import {
    ShortTitle,
    ShortContent,
    ShortTextCardContainer,
    EndingLine,
    ShortContentImg,
    ShortContentContainer
} from 'src/components/articleCard/css/styleTextCard.style';
import StyleCardInfo from './styleCardInfo';
import 'src/App.css';
import {Link} from "react-router-dom";

function StyleTextCard({
    articleId,
    title,
    shortContent,
    authorName,
    likeCount,
    commentCount,
    readCount,
    pictureList,
    priority
}) {
    return (
       <ShortTextCardContainer>
           <div style={{position: 'relative', display: "flex", alignItems:'center'}}>
               {
                   priority
                       ? <div style={{
                           padding: '2px 5px',
                           background: 'rgb(236, 92, 87)',
                           color: 'white',
                           marginRight: 5,
                           borderRadius: 5,
                           height: 24
                       }}>
                           置顶
                       </div>
                       : <></>
               }
               <Link to={`/detail/${articleId}`}>
                   <ShortTitle>
                       { title }
                   </ShortTitle>
               </Link>
           </div>
           <ShortContentContainer>
               <ShortContent>
                   { shortContent }
               </ShortContent>
               {
                   pictureList && pictureList.length > 0 ?
                       <ShortContentImg src={pictureList[0].url + `?x-oss-process=image/resize,m_fill,h_${100 * 2},w_${150 * 2}`}/> : null
               }
           </ShortContentContainer>
           <StyleCardInfo
               authorName={authorName}
               likeCount={likeCount}
               commentCount={commentCount}
               readCount={readCount}
           />
           <EndingLine />
       </ShortTextCardContainer>
    );
}

StyleTextCard.propTypes = {
    articleId: string.isRequired,
    title: string.isRequired,
    shortContent: string.isRequired,
    authorName: string.isRequired,
    likeCount: number,
    commentCount: number,
    readCount: number,
    pictureList: array,
    priority: number
}

const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyleTextCard);
