import {post, get} from "./network";

const communityServicePrefix = "/community";

export async function netFollow(param) {
    return post(communityServicePrefix + '/author/v1/follow', param);
}

export async function netUnfollow(param) {
    return post(communityServicePrefix + '/author/v1/unfollow', param);
}

export async function netMyFollowingList() {
    return get(communityServicePrefix + '/author/v1/my_following_list');
}

export async function netFollowingList(followerId, param) {
    return get(communityServicePrefix + '/author/v1/op/following_list/' + followerId, param);
}

export async function netFansList(authorId, param) {
    return get(communityServicePrefix + '/author/v1/op/fans_list/' + authorId, param);
}

export async function netAuthorInfo(authorId) {
    return get(communityServicePrefix + '/author/v1/op/info/' + authorId);
}

export async function netToAuthorCommentList(param) {
    return get(communityServicePrefix + `/author/v1/op/to_author_comment_list`, param);
}

export async function netAuthorLikedArticle(param) {
    return get(communityServicePrefix + '/author/v1/op/like_article_list', param);
}

export async function netToAuthorCollectedArticle(param) {
    return get(communityServicePrefix + `/author/v1/op/collect_article_list`, param);
}
