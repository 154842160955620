import React from "react";
import {connect} from "react-redux";
import moduleCss from "./authorCommentCard.module.css";
import {shape} from "prop-types";
import AvatarImg from "../../../../../../components/avatar/avatarImg";
import {Link} from "react-router-dom";

function AuthorCommentCard({comment}) {
    return (
        <div className={moduleCss.container}>
            <div className={moduleCss.authorInfo}>
                <AvatarImg size={24} src={comment.userAvatar}></AvatarImg>
                <div className={moduleCss.authorInfoText}>{comment.userNickname}</div>
                <div className={moduleCss.authorInfoText}>品论了文章</div>
                <div className={moduleCss.authorInfoText}>2021.09.19 13:34:65</div>
            </div>
            <div className={moduleCss.title}>
                <Link to={'/detail/' + comment.articleId}>《{comment.articleTitle}》</Link>
            </div>
            <div className={moduleCss.content}>
                {comment.content}
            </div>
        </div>
    )
}

AuthorCommentCard.propTypes = {
    comment: shape({}),
}


const mapDispatchToProps = () => {
    return {}
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AuthorCommentCard);
